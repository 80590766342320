import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, map} from 'rxjs';
import {
  Debtor,
  InterviewReport,
  IsRemitInfo,
  MemberInfo,
  RiskCheckReport,
  ShoppingInfo,
  ValidationReport,
} from './loan.interface';

import {Inject, Injectable} from '@angular/core';
import {INDO_WORKER_LOAN_BASE_URL} from './loan.injection-token';

// const dataSourceUrl = environment.loanDatabaseUrl;
const customerAnalytics =
  'https://debtor-verification.dev.indosuara.com/staff/v0/customer-analytics';

interface GetDebtorResponse {
  code: number;
  data: Debtor[];
  message: string;
  status: string;
}

interface CustomerAnalyticsResponse {
  code: number;
  data: {
    arcNo?: string;
    homeShopping: ShoppingInfo[];
    memberInfo?: MemberInfo;
    isRemit: IsRemitInfo[];
    memberId: string;
    phoneNo?: string;
  };
  message: string;
  status: string;
}

interface PostReportResponse {
  code: number;
  message: string;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoanService {
  constructor(
    private http: HttpClient,
    @Inject(INDO_WORKER_LOAN_BASE_URL) private dataSourceUrl: string,
  ) {}

  getCustomerAnalytics(phoneNo: string) {
    const data = {
      phoneNo: phoneNo,
    };

    const result = this.http
        .post<CustomerAnalyticsResponse>(customerAnalytics, data)
        .pipe(
            map((data) => {
              return data.data;
            }),
        );

    return result;
  }

  getDebtor(id: string) {
    const result = this.http
        .get<GetDebtorResponse>(this.dataSourceUrl + `/${id}`)
        .pipe(
            map((data) => {
              return data.data[0];
            }),
        );
    return result;
  }

  getNotValidatedDebtorList(): Observable<Debtor[]> {
    return this.getDebtorList('need_validation');
  }

  getNotInterviewedDebtorList(): Observable<Debtor[]> {
    return this.getDebtorList('need_interview');
  }

  getNotRiskCheckedDebtorList(): Observable<Debtor[]> {
    return this.getDebtorList('need_risk_check');
  }

  getDebtorList(filter?: string): Observable<Debtor[]> {
    const param = filter ?
      {params: new HttpParams().set('status', filter)} :
      {};
    const result = this.http
        .get<GetDebtorResponse>(this.dataSourceUrl, {...param})
        .pipe(
            map((data) => {
              return data.data;
            }),
        );
    return result;
  }

  reportValidation(data: ValidationReport): Observable<PostReportResponse> {
    const result = this.http
        .post<PostReportResponse>(this.dataSourceUrl + ':validate', data);
    return result;
  }

  reportRiskCheck(data: RiskCheckReport): Observable<PostReportResponse> {
    const result = this.http
        .post<PostReportResponse>(this.dataSourceUrl + ':approve', data);
    return result;
  }

  reportInterview(data: InterviewReport): Observable<PostReportResponse> {
    const result = this.http
        .post<PostReportResponse>(this.dataSourceUrl + ':interview', data);
    return result;
  }
}
