import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericGoldService, GoldShopsQueryParams, QueryResult} from './gold-generic-service';


export interface GoldPaymentChannel{
  id: string
  name: string
  algorithm?: string
  detail: string
  isDeleted: string,
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
}

export interface GoldPaymentChannelResponse{
  message: string
  body: GoldPaymentChannel
  total: number
}

@Injectable({
  providedIn: 'root',
})
export class GoldPaymentChannelService extends
  GenericGoldService<GoldPaymentChannel> {
  constructor() {
    super('payment-method', 'payment-methods');
  }

  override query(params: GoldShopsQueryParams):
  Observable<QueryResult<GoldPaymentChannel>> {
    return this.http.post<QueryResult<GoldPaymentChannel>>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`, params);
  }
}
