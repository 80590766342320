import {Component} from '@angular/core';

@Component({
  selector: 'indosuara-manager-home',
  template: `
    <div class="button-container">
      <h3>Shortcuts</h3>
      <a mat-button href="/manager/users" color="primary">
        Manage Staff Account</a
      >
      <a mat-button href="/inventory/home" color="primary">
        Go To Inventory Dashboard</a
      >
      <a mat-button href="/inventory/products" color="primary">
        Go To Product List</a
      >
      <a mat-button href="/inventory/stock-keeping" color="primary">
        Go To Stock Management</a
      >
      <a mat-button href="/sales/home" color="primary">
        Go To Sales Management</a
      >
      <a mat-button href="/customer-service" color="primary">
        Search Customer</a
      >
    </div>
  `,
  styles: [
    `
      .button-container {
        margin: 10px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      a {
        text-align: left;
      }
    `,
  ],
})
/**
 * Manager Home Component
 */
export class ManagerHomeComponent {}
