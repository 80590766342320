import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';
import {GoldShopsQueryParams, QueryResult} from './gold-generic-service';
import {GoldShopResponse} from './gold-product.service';
import {GoldShopApiResponse} from './kyc/user-verification.service';


export interface IncomingOrder {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  manufacturerId: string;
  stockLocationId: string;
  date: string;
  note: string;
  items: IncomingOrderItem[];
  version: string;
  locationName?: string;
  manufacturerName?:string;
}

export interface IncomingOrderItem {
  productId: string;
  variationId: string;
  quantity: number;
  capitalPrice: number;
}


export interface IncomingOrderForm{
  manufacturerId: string | null
  stockLocationId: string | null
  date: Date | null
  items: Partial<IncomingOrderItemForm>[] | null
  note: string | null
}
export interface IncomingOrderItemForm{
  variationId: string | null
  capitalPrice: number | null
  quantity: number | null
}

@Injectable({
  providedIn: 'root',
})
export class IncomingOrderService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) {}

  name = 'incoming-order';
  namePlural = 'incoming-orders';


  create(order: Partial<IncomingOrderForm>) : Observable<GoldShopApiResponse> {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}`,
        order,
    );
  }

  get(id: string): Observable<IncomingOrder> {
    return this.http.get<{body: IncomingOrder}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
    ).pipe(map((v)=> {
      return v.body;
    }));
  }

  query(params: GoldShopsQueryParams): Observable<QueryResult<IncomingOrder>> {
    return this.http.post<QueryResult<IncomingOrder>>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`,
        params,
    );
  }

  update(id: string, product: {name: string}) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
        product,
    );
  }
}
