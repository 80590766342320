// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  firebase: {
    projectId: 'sacred-lane-331603',
    appId: '1:122892781115:web:3d441b98f78967c9e17e1e',
    databaseURL: 'https://sacred-lane-331603-default-rtdb.firebaseio.com',
    storageBucket: 'sacred-lane-331603.appspot.com',
    locationId: 'asia-northeast2',
    apiKey: 'AIzaSyDBMAh_QsXv_x8HTwavfNCBmaexsP6OaIY',
    authDomain: 'sacred-lane-331603.firebaseapp.com',
    messagingSenderId: '122892781115',
  },
  production: false,
  elastic: {
    enterpriseSearchUrl: 'https://0942f59ce4a74468b048f125979df154.ent-search.asia-east1.gcp.elastic-cloud.com',
    SearchApiKey: 'search-q7sxnwecgphpx24mhdhmy2oh',
    productServiceEngineName: 'products',
  },
  loanDatabaseUrl: 'https://debtor-verification.dev.indosuara.com/staff/v0/debtors',
  loanDatabaseToken: 'token',
  useEmulators: false,
  staffTenantId: 'employee-bml6n',
  indosuaraApiUrl: 'https://api.staging.indosuara.com',
  taiwanAddressUrl: 'https://storage.googleapis.com/indosuara-taiwan-address/taiwan-address.json',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such
 * as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production
 * mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
