import {catchError, from, map, Observable, throwError} from 'rxjs';

import {Permission, StaffService} from './staff.service';


import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {FirebaseError} from 'firebase/app';
import {Staff} from './staff.interface';

import {Functions, httpsCallable} from '@angular/fire/functions';


export interface StaffForm {
  name: string,
  email: string,
  password: string,
}
function checkAppError(res: any) {
  if (!res?.code) return res;
  if (res?.code != 200 ) {
    throw new Error(res.message);
  }
  return res;
}

function transformError(
    error: HttpErrorResponse | string | FirebaseError,
) {
  let errorMessage = 'An unknown error has occurred';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (
    error instanceof HttpErrorResponse &&
    error.error instanceof ErrorEvent
  ) {
    errorMessage = `Error Event! ${error.error.message}`;
  } else if (error instanceof HttpErrorResponse && error.status) {
    errorMessage = `Request failed with ${error.status} ${error.statusText}`;
  } else if (error instanceof FirebaseError) {
    errorMessage = `${error.message}`;
  } else if (error instanceof Error) {
    errorMessage = `error ${error.message}`;
  }
  return throwError(() => errorMessage);
}

// V1 ENDPOINT staffManagement
// V2 ENDPOINT adminAccountManagement
@Injectable({
  providedIn: 'root',
})
export class FirebaseStaffService extends StaffService {
  private functions = inject(Functions);
  constructor(
  ) {
    super();
  }

  createStaff(data: StaffForm): Observable<any> {
    return this.callFirebaseFunction('staffManagement-createNewStaff', data);
  }

  disableStaff(userid: string): Observable<any> {
    const endpoint = 'staffManagement-disableStaff';
    return this.callFirebaseFunction(endpoint, {
      id: userid,
    });
  }

  generatePermissionState(
      staff: Staff,
      appPermissions: string[],
  ): Permission[] {
    const perms:Permission[] = [];
    for (const perm of appPermissions) {
      perms.push(this.generatePermission(perm, staff));
    }
    return perms;
  }

  listAppPermissions(): Observable<string[]> {
    const endpoint = 'staffManagement-listAllPermissions';
    return this.callFirebaseFunction(endpoint, {})
        .pipe(map(this.extractPermissionsFromResponse));
  }

  listStaff(): Observable<Staff[]> {
    return this.callFirebaseFunction('adminAccountManagement-listAccounts');
  }

  setStaffPermissions(uid:string, permissions:string[]): Observable<any> {
    return this.callFirebaseFunction('staffManagement-editPermission', {
      id: uid,
      permissions: permissions,
    });
  }

  setStaffClaims(
      uid: string,
      claims:
    {
      manager: boolean;
      staff: boolean;
      idpAgentCode: string;
      permissions: string[];
    }): Observable<any> {
    return this.callFirebaseFunction('adminAccountManagement-updateClaims', {
      uid: uid,
      claims: claims,
    });
  }

  private generatePermission(perm: string, staff: Staff): Permission {
    return {
      name: perm,
      permitted: staff.permissions.includes(perm),
    };
  }

  private extractPermissionsFromResponse(response:any) {
    return response.permissions;
  }

  private callFirebaseFunction(endpoint: string, data: any = {}) {
    const callable = httpsCallable(this.functions, endpoint);
    const invoke = callable(data);
    const response$ = from(invoke).pipe(
        map((res) => res.data),
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
}
