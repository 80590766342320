import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {Staff} from '../staff/staff.interface';
import {Permission} from '../staff/staff.service';
import {
  SetPermissionDialogComponent,
} from './set-permission-dialog/set-permission-dialog.component';

@Injectable({
  providedIn: 'root',
})
/**
 *
 */
export class StaffDialogService {
  constructor(private dialog: MatDialog) {

  }
  showStaffPermissionForm(
      staff: Staff,
      permissions:Permission[],
  ): Observable<string | undefined> {
    const dialogRef = this.dialog.open(SetPermissionDialogComponent,
        {
          width: '600px',
          data: {
            staff: staff,
            permissions: permissions,
          },
        });

    return dialogRef.afterClosed();
  }
}
