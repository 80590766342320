
export interface SideNavNode {
  name: string;
  route?: string;
  children?: SideNavNode[];
  status?: 'production' | 'development'
  requiredPermissions?: DomainPermission[],
  icon?: string | undefined;
}

export type DomainPermission = 'superadmin' | 'hs' | 'loan'
| 'dev' | 'indopos' | 'gold' | 'shop'

export const SIDENAV_DATA: SideNavNode[] = [
  {
    name: 'Manager',
    route: '/manager',
    icon: 'admin_panel_settings',
    children: [
      {name: 'Staff', route: '/manager/users'},
      // {name: 'Exchange Rate', route: '/exchange-rate'},
    ],
    status: 'production',
    requiredPermissions: ['superadmin'],
  },
  {
    name: 'Indopos',
    icon: 'local_shipping',
    route: '/indopos',
    children: [
      {name: 'Home', route: '/indopos/home'},
      {name: 'Issue', route: '/indopos/issue'},
      {name: 'Drafts', route: '/indopos/sales-drafts'},
      {name: 'Penjualan Kardus', route: '/indopos/sales'},
      {name: 'Pembuatan Kardus', route: '/indopos/code-creation'},
      {name: 'Lihat Kardus', route: '/indopos/boxes'},
      {name: 'Container Forwarder', route: '/indopos/container'},
      {name: 'Summary', route: '/indopos/summary'},
    ],
    status: 'production',
    requiredPermissions: ['indopos'],
  },
  {
    name: 'E-masKu',
    icon: 'diamond',
    route: '/emasku',
    children: [
      {name: 'Home', route: '/emasku'},
    ],
    status: 'production',
    requiredPermissions: ['gold'],
  },
  {
    name: 'Shop',
    icon: 'store',
    route: '/shops',
    children: [
      {
        name: 'Inventory',
        children: [
          {name: 'Products', route: '/shops/shop-items'},
          {name: 'Incoming Orders', route: '/shops/shop-purchase-order'},
        ],
      },
      {
        name: 'Sales',
        children: [
          {name: 'Orders', route: '/shops/shop-orders'},
          {name: 'Summary', route: '/shops/shop-order-summary'},
          {name: 'Summary Item', route: '/shops/shop-order-item-summary'},
        ],
      },
      {
        name: 'Configurations',
        requiredPermissions: ['superadmin'],
        children: [
          {
            name: 'Features',
            route: '/shops/shop-feature',
            requiredPermissions: ['superadmin'],
          },
          {
            name: 'Shop Branch',
            route: '/shops/shop-branches',
            requiredPermissions: ['superadmin'],
          },
        ],
      },

    ],
    status: 'production',
    requiredPermissions: ['shop'],
  },
  {
    name: 'Flight',
    icon: 'flight_takeoff',
    route: '/flight',
    children: [
      {name: 'Home', route: '/flight'},
    ],
    status: 'development',
  },
  {
    name: 'Developer',
    icon: 'code',
    route: '/developer',
    children: [
      {name: 'Basic', route: '/developer/basic'},
    ],
    status: 'production',
    requiredPermissions: ['dev'],
  },
  {
    name: 'Others',
    icon: 'more_horiz',
    children: [
      {name: 'indosuara.biz', route: 'http://www.indosuara.biz/20220510/'},
      {name: 'Editor Berita', route: 'https://halo.indosuara.com/admin'},
      {name: 'Remit Kilat', route: 'https://www.remitkilat.com/agent/'},
      {name: 'FB Indosuara', route: 'https://www.facebook.com/indosuaraonline'},
    ],
    status: 'production',
  },
];
