import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {FLIGHT_BASE_URL} from './config';
import {AvailableFlight, FlightJourney} from './flight-search.service';

export interface OrderWriteRequest{
  name:string;
  email:string;
  phone:string;
  passport:string;
  arc:string;
}

export interface OrderResponse{
  body: Order;
  message: string;
  totalData?: number;
}

export interface QueryOrderResponse{
  body: Order[];
  message: string;
  totalData?: number;
}

export interface QueryOrderParams{
  pageNumber: number | null | undefined;
  pageSize: number | null | undefined;
}


export interface MarkOrderResponse{
  message: string;
}


export interface Order{
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  version: string;
  name: string;
  email: string;
  phone: string;
  passport: string;
  arc: string;
  orderStatus: OrderStatus;
  bookedFlight: AvailableFlight;
}

export enum OrderStatus {
  SUBMITTED = 'submitted',
  PROCESSED = 'processed',
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

@Injectable({
  providedIn: 'root',
})
export class FlightOrderService {
  constructor(
    private http: HttpClient,
    @Inject(FLIGHT_BASE_URL) private flightApiUrl: string,
  ) { }


  createOrder(transactionKey: string, index: number,
      params: OrderWriteRequest): Observable<OrderResponse> {
    return this.http.post<OrderResponse>(
        this.flightApiUrl +
        `/flight/api/v0/user/flight/${transactionKey}/index/${index}/order`,
        params,
    );
  }

  query(params: QueryOrderParams):Observable<QueryOrderResponse> {
    return this.http.post<QueryOrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/orders`,
        params,
    );
  }

  get(id: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/order/${id}`,
    );
  }

  update(order: Partial<Order>): Observable<MarkOrderResponse> {
    return this.http.put<MarkOrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/order/${order.id}`, order);
  }

  markAsProcessed(id: string): Observable<MarkOrderResponse> {
    return this.http.put<MarkOrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/order/${id}/processed`, {});
  }

  markAsConfirmed(id: string): Observable<MarkOrderResponse> {
    return this.http.put<MarkOrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/order/${id}/confirmed`, {});
  }

  markAsCancelled(id: string): Observable<MarkOrderResponse> {
    return this.http.put<MarkOrderResponse>(
        this.flightApiUrl + `/flight/api/v0/staff/order/${id}/cancelled`, {});
  }

  getInvalidOrderResponseWithMessage(message: string): OrderResponse {
    return {
      body: {
        id: '',
        createdAt: '',
        createdBy: '',
        updatedAt: '',
        updatedBy: '',
        version: '',
        name: '',
        email: '',
        phone: '',
        passport: '',
        arc: '',
        orderStatus: OrderStatus.CANCELLED,
        bookedFlight: this.getInvalidAvailableFlight(),
      },
      message: message,
    };
  }

  getOrder(id: string): Observable<OrderResponse> {
    return this.http.get<OrderResponse>(
        this.flightApiUrl + `/flight/api/v0/user/order/${id}`,
    ).pipe(map((orderResponse)=> {
      orderResponse.body.orderStatus = this.mapOrderStatus(orderResponse.
          body.orderStatus);
      return orderResponse;
    }));
  }

  private getInvalidAvailableFlight(): AvailableFlight {
    return {
      indexNo: 0,
      validatingAirline: '',
      validatingAirlineName: '',
      totalPrice: 0,
      totalPriceWithoutTax: 0,
      adultPrice: 0,
      adultPriceWithoutTax: 0,
      adultCount: 0,
      adultAvailSeats: 0,
      childPrice: 0,
      childPriceWithoutTax: 0,
      childCount: 0,
      infantPrice: 0,
      infantPriceWithoutTax: 0,
      infantCount: 0,
      departureInfo: this.getInvalidFlightJourney(),
      returnInfo: this.getInvalidFlightJourney(),
    };
  }

  private getInvalidFlightJourney(): FlightJourney {
    return {
      totalDepartureCountry: '',
      totalDepartureCity: '',
      totalDepartureAirport: '',
      totalDepartureDate: '',
      totalArrivalCountry: '',
      totalArrivalCity: '',
      totalArrivalAirport: '',
      totalArrivalDate: '',
      totalFlightDuration: '',
      transferAmount: 0,
      segmentDetails: null,
    };
  }

  private mapOrderStatus(status: string): OrderStatus {
    if (Object.values(OrderStatus).includes(status as OrderStatus)) {
      return status as OrderStatus;
    }
    throw new Error('Invalid order status');
  }
}
