import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {ISMEMBER_BASE_URL} from '../config';

export interface MemberQueryParams {
  pageNumber?: number | null
  pageSize?: number | null
  sortBy?: string | null
  sortDirection?: number | null
  name?: string | null
  passportNumber?: string | null,
  phoneNumber?: string | null,
  arcNumber?: string | null,
}

export interface MembersProfile{
  body?: MemberProfile[]
  totalData: number
}

export interface MemberProfile{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  name: string
  phoneNumber: string
  arcNumber: string
  bp2miId: string
  hasBp2mi: boolean
  passportNumber: string
  address: string
}


@Injectable({
  providedIn: 'root',
})
export class MemberProfileService {
  constructor(
    private http: HttpClient,
    @Inject(ISMEMBER_BASE_URL) private indoposApiUrl: string,
  ) {}
  queryMembers(params?: Partial<MemberQueryParams>):Observable<MembersProfile> {
    return this.http.post<MembersProfile>(
        this.indoposApiUrl + '/indopos/sales/v0/members',
        params,
    );
  }
  getMemberByPhoneNumber(phone: string): Observable<MemberProfile | null> {
    return this.queryMembers({
      pageNumber: 0,
      pageSize: 1,
      phoneNumber: phone,
    }).pipe(
        map((res)=> res.body?.[0] ?? null),
    );
  }
}
