import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';
import {GoldShopResponse} from './gold-product.service';


export interface GoldRate{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  rate: string
}


export interface GoldRates{
  totalData: number
  body: GoldRate[]
}


export interface GoldRateQueryParams{
  pageNumber?: number
  pageSize?: number
  sortBy?: string
  sortDirection?: '' | 'asc' | 'desc'
}

@Injectable({
  providedIn: 'root',
})
export class GoldRateService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) { }

  setGoldRate(rate: string): Observable<GoldShopResponse> {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/rate',
        {
          rate: rate,
        },
    );
  }

  getGoldRate(): Observable<GoldRate> {
    return this.http.get<{body:GoldRate}>(
        this.goldshopApiUrl + '/gold/api/v0/staff/rate',
    ).pipe(
        map((v)=> v.body),
    );
  }

  queryRateChanges(params:GoldRateQueryParams): Observable<GoldRates> {
    return this.http.post<GoldRates>(
        this.goldshopApiUrl + '/gold/api/v0/staff/rates',
        {
          ...params,
          sortDirection: params.sortDirection == 'asc' ? 1 : -1,
        },
    );
  }
}
