import {Component, OnDestroy, OnInit} from '@angular/core';

import {MediaObserver} from '@angular/flex-layout';
import {AuthService} from '@indosuara/auth';
import {combineLatest} from 'rxjs';
import {tap} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {environment} from '../environments/environment';

@Component({
  selector: 'indosuara-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})


export class AppComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  opened = false;

  constructor(
    public authService: AuthService,
    public media: MediaObserver,
  ) {
    this.auth$ = this.authService.authState();
  }

  auth$;

  isDevelopment(): boolean {
    return !environment.production;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.subs.sink = combineLatest([
      this.media.asObservable(),
      this.authService.authState(),
    ])
        .pipe(
            tap(([mediaValue, authStatus]) => {
              if (!authStatus?.staff) {
                this.opened = false;
              } else {
                if (mediaValue[0].mqAlias === 'xs') {
                  this.opened = false;
                } else {
                  this.opened = true;
                }
              }
            }),
        )
        .subscribe();
  }
}
