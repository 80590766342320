import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, map, of} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';
import {QueryResult} from './gold-generic-service';
import {GoldShopApiResponse} from './kyc/user-verification.service';


export interface GoldShopResponse{
  status?: string;
  code?: number;
  message?: string;
  error?: string;
}

export interface GoldProductQueryParams{
  name?: string | null;
  hasAvailableStock?: 'Y' | 'N' | '' | null;
  pageNumber?: number | null | undefined;
  pageSize?: number | null | undefined;
}

export interface GoldProducts{
  body: GoldProduct[]
  totalData: number
}

export type GoldProduct = GoldProductBasicInfo & GoldProductUpdatableField

export interface GoldProductBasicInfo{
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  version: string;
  categoryId: string;
}

export interface GoldProductUpdatableField{
  shopId?: string | null;
  categoryId?: string | null;
  sku?: string | null;
  name?: string | null;
  images?: (string | null)[] | null;
  videos?: (string | null)[] | null;
  description?: string | null;
  tierVariations?: Partial<TierVariation>[];
  variations?: Partial<Variation>[];
}

export interface TierVariation{
  images?: string[] | null;
  name?: string | null;
  options?: string[] | null;
}

export interface VariationQueryParams{
  productId: string;
  pageNumber: number;
  pageSize: number;
  isDeleted: string;
}

export type Variation = VariationBasicInfo & VariationUpdatableField

export interface VariationBasicInfo{
  id: string;
  name: string;
  productId: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  version: string;
  liveStock: number;
  basePrice?: string | number | null;
}

export interface VariationUpdatableField{
  variationIndex?: number[] | null;
  isDefault?: string | null;
  sku?: string | null;
  price?: string | number | null;
  stock?: number | null;
  isDeleted?: string | null;
  image?: string|null;
  priceAlgorithm?: string|null;
  weight?: string|null;
}

export interface VariationRead {
  id?: string;
  productId?: string;
  variationIndex?: number[];
  isDefault?: string;
  sku?: string;
  price?: number;
  stock?: number;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  version?: string;
  product?: GoldProduct;
  variationName?: string;
  liveStock?: number;
  priceAlgorithm?: string;
  weight?: string;
  image?: string;
}


export interface PriceAlgorithm{
  id: string
  name: string
}

@Injectable({
  providedIn: 'root',
})
export class GoldProductService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) { }

  queryProducts(params: GoldProductQueryParams)
  : Observable<GoldProducts> {
    return this.http.post<GoldProducts>(
        this.goldshopApiUrl + '/gold/api/v0/staff/products',
        params,
    ).pipe(map((resp)=>{
      const response: GoldProducts = {
        body: resp.body.map((p)=> this.transformProductPrice(p)),
        totalData: resp.totalData,
      };
      return response;
    }));
  }

  queryVariantions(params: Partial<GoldProductQueryParams>, version?: number)
  : Observable<QueryResult<VariationRead>> {
    if (version == 1) {
      return this.queryVariationsV1(params);
    }
    return this.queryVariationsV2(params);
  }

  private queryVariationsV2(params: GoldProductQueryParams):
  Observable<QueryResult<VariationRead>> {
    return this.http.post<QueryResult<VariationRead>>(
        this.goldshopApiUrl + '/gold/api/v0/staff/variation-aggregates',
        params,
    ).pipe(map((resp) => {
      const response = {
        body: resp.body?.map((p) => this.transformVariation(p)),
        totalData: resp.totalData,
      };
      return response;
    }));
  }


  private queryVariationsV1(params: GoldProductQueryParams):
  Observable<QueryResult<VariationRead>> {
    return this.http.post<QueryResult<VariationRead>>(
        this.goldshopApiUrl + '/gold/api/v0/staff/variations',
        params,
    ).pipe(map((resp) => {
      const response = {
        body: resp.body?.map((p) => this.transformVariation(p)),
        totalData: resp.totalData,
      };
      return response;
    }));
  }

  getVariation(id: string): Observable<VariationRead> {
    return this.http.get<VariationRead>(
        this.goldshopApiUrl + `/gold/api/v0/staff/variation-aggregate/${id}`,
    ).pipe(map((v)=> {
      return this.transformVariation(v);
    }));
  }


  createProduct(product: Partial<GoldProductUpdatableField>) {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product',
        product,
    );
  }

  getProduct(id: string): Observable<GoldProduct> {
    return this.http.get<{body: GoldProduct}>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' + id,
    ).pipe(
        map((v)=> v.body),
        map((product)=>{
          return this.transformProductPrice(product);
        }),
    );
  }

  deleteProduct(id: string): Observable<GoldShopResponse> {
    return this.http.delete<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' + id,
    );
  }

  private transformProductPrice(
      product: GoldProductBasicInfo & GoldProductUpdatableField) {
    const pr = _.cloneDeep(product);
    pr.variations?.forEach((variation) => {
      const price = (variation.price ?? 0) as number / 100000;
      const basePrice = (variation.basePrice ?? 0) as number / 100000;
      variation.price = price.toString();
      variation.basePrice = basePrice.toString();
    });
    return pr;
  }

  private transformVariation(
      variation: VariationRead) {
    const pr = _.cloneDeep(variation);
    const price = (variation.price ?? 0) as number / 100000;
    pr.price = price;
    return pr;
  }

  updateProduct(id: string, product: Partial<GoldProductUpdatableField>) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' + id,
        product,
    );
  }

  uploadImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<GoldShopApiResponse & {url:string}>(
        `${this.goldshopApiUrl}/gold/api/v0/staff/ecommerce/upload-image`,
        formData,
    ).pipe(map((v)=>v.url));
  }

  updateVariation(productId: string,
      variationId: string, variation: Partial<Variation>) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' +
        productId + '/variation/' + variationId,
        variation,
    );
  }

  removeOption(productId: string, tierId: number, optionsId: number) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' +
      productId + '/tier/' + tierId + '/option/' + optionsId + '/remove',
        {},
    );
  }

  addOption(productId: string, tierId: number, option: string) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' +
      productId + '/tier/' + tierId + '/add',
        {
          'name': option,
        },

    );
  }

  updateTier(productId: string, tierId: number, tier: TierVariation) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/product/' +
      productId + '/tier/' + tierId,
        tier,
    );
  }

  listPriceAlgorithm(): Observable<PriceAlgorithm[]> {
    return of([
      {
        id: 'fixed',
        name: 'Fixed',
      },
      {
        id: 'weighted',
        name: 'Gold Weighted',
      },
    ]);
  }
}
