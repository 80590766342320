/* eslint-disable no-tabs */
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {FLIGHT_BASE_URL} from './config';


export interface FlightQueryParams{
  ticketType: string;
	departureCountry: string;
	arrivalCountry: string;
	departureCity: string;
	arrivalCity: string;
	departureDate: string;
	returnDate?: string;
	adultCount: number;
	childCount: number;
	infantCount: number;
}

export interface FlightResponse{
  body: Flight;
  message: string;
  totalData: number;
}

export interface Flight{
  transactionKey: string;
  searchKey: string;
  availableFlights: AvailableFlight[];
}

export interface AvailableFlight{
	indexNo: number;
	validatingAirline: string;
	validatingAirlineName: string;
	totalPrice: number;
	totalPriceWithoutTax: number;
	adultPrice: number;
	adultPriceWithoutTax: number;
	adultCount: number;
	adultAvailSeats: number;
	childPrice: number;
	childPriceWithoutTax: number;
	childCount: number;
	infantPrice: number;
	infantPriceWithoutTax: number;
	infantCount: number;
	departureInfo: FlightJourney;
	returnInfo: FlightJourney;
}

export interface FlightJourney{
  totalDepartureCountry: string;
	totalDepartureCity: string;
	totalDepartureAirport: string;
	totalDepartureDate: string;
	totalArrivalCountry: string;
	totalArrivalCity: string;
	totalArrivalAirport: string;
	totalArrivalDate: string;
	totalFlightDuration: string;
	transferAmount: number;
	segmentDetails: SegmentDetail[] | null;
}

export interface SegmentDetail{
  segmentIndex: number;
	cabinClass: string;
	baggageDesc: string;
	airlineCode: string;
	airlineName: string;
	flightNumber: string;
	segmentDepartureCountry: string;
	segmentDepartureCity: string;
	segmentDepartureAirport: string;
	segmentDepartureTerminal: string;
	segmentDepartureDate: string;
	segmentArrivalCountry: string;
	segmentArrivalCity: string;
	segmentArrivalAirport: string;
	segmentArrivalTerminal: string;
	segmentArrivalDate: string;
	segmentFlightDuration: string;
	segmentTransferDuration: string;
}
@Injectable({
  providedIn: 'root',
})
export class FlightSearchService {
  constructor(
    private http: HttpClient,
    @Inject(FLIGHT_BASE_URL) private flightApiUrl: string,
  ) { }


  searchFlights(params: FlightQueryParams): Observable<FlightResponse> {
    return this.http.post<FlightResponse>(
        this.flightApiUrl + '/flight/api/v0/user/search-flights',
        params,
    );
  }


  getFlight(transactionKey: string, index: number):
  Observable<AvailableFlight> {
    return this.http.get<{body: AvailableFlight}>(
        this.flightApiUrl +
        `/flight/api/v0/user/flight/${transactionKey}/index/${index}`,
    ).pipe(map((v)=> {
      return v.body;
    }));
  }
}


