import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AskTextDialogComponent} from './ask-text-dialog.component';
import {DatePickerDialogComponent} from './date-picker-dialog.component';
import {SimpleDialogComponent} from './simple-dialog.component';

@Injectable({
  providedIn: 'root',
})

export class UiService {
  constructor(private snackBar: MatSnackBar, private dialog: MatDialog) {}

  showToast(message: string, action = 'Close', config?: MatSnackBarConfig) {
    this.snackBar.open(
        message,
        action,
        config || {verticalPosition: 'bottom', duration: 7000},
    );
  }


  showDialog(
      title: string,
      content: string,
      okText = 'OK',
      cancelText?: string,
      customConfig?: MatDialogConfig,
  ): Observable<boolean> {
    const dialogRef = this.dialog.open(
        SimpleDialogComponent,
        customConfig || {
          width: '350px',
          data: {title, content, okText, cancelText},
        },
    );

    return dialogRef.afterClosed();
  }

  /**
   * Shows a dialog with a text input field.
   *
   * @param {string} title - The title of the dialog.
   * @param {string} content - The content of the dialog.
   * @param {string} [okText='OK'] - The text for the OK button.
   * @param {string} [cancelText] - The text for the Cancel button.
   * @param {MatDialogConfig} [customConfig] - Custom configuration
   * for the dialog.
   * @return {Observable<string>} An observable that emits the
   * user's input as a string
   * when the dialog is closed.
   */
  showAskTextDialog(
      title: string,
      content: string,
      okText = 'OK',
      cancelText?: string,
      customConfig?: MatDialogConfig,
  ): Observable<string> {
    const dialogRef = this.dialog.open(
        AskTextDialogComponent,
        customConfig || {
          width: '350px',
          data: {title, content, okText, cancelText},
        },
    );
    return dialogRef.afterClosed();
  }

  showTextFormDialog(opt:{
    title: string,
    content: string,
    okText?: string,
    cancelText?: string,
    customConfig?: MatDialogConfig,
    initText?: string,
  }): Observable<string> {
    const dialogRef = this.dialog.open(
        AskTextDialogComponent,
        opt.customConfig || {
          width: '350px',
          data: {
            title: opt.title,
            content: opt.content,
            okText: opt.okText ?? 'OK',
            cancelText: opt.cancelText ?? 'Cancel',
            initText: opt.initText ?? '',
          },
        },
    );
    return dialogRef.afterClosed();
  }

  showDatePickerDialog(
      title: string,
      content: string,
      okText?: string,
      cancelText?: string,
      customConfig?: MatDialogConfig,
  ): Observable<Date | null> {
    const dialogRef = this.dialog.open(
        DatePickerDialogComponent,
        customConfig || {
          width: '350px',
          data: {title, content, okText, cancelText},
        },
    );

    return dialogRef.afterClosed();
  }
}
