import {HttpClient} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';
import {GoldShopResponse} from './gold-product.service';
import {GoldShopApiResponse} from './kyc/user-verification.service';

export interface GoldShop{
  id: string
  name: string
}

export interface GoldCategory{
  id: string
  name: string
}

export interface GoldCart{
  id: string
  userId: string
  items: CartItem[]
}

export interface CartItem{
  shopId: string
  productId: string
  variationId: string
  quantity: string
}


export interface QueryResult<T>{
  body?: T[] | null
  totalData: number
  message?: string
}

export interface GoldShopsQueryParams{
  name?: string | null;
  pageNumber?: number | null | undefined;
  pageSize?: number | null | undefined;
  isActive?: 'Y' | 'N' | null | undefined;
}


export class GenericGoldService<T> {
  public http = inject(HttpClient);
  public goldshopApiUrl = inject<string>(GOLDSHOP_BASE_URL);
  constructor(
    public name: string,
    public namePlural: string,
  ) {
    if (!name) throw new Error('cannot construct repository without a name');
  }

  create<T>(shop: Partial<T>) : Observable<GoldShopApiResponse> {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}`,
        shop,
    );
  }

  get(id: string): Observable<T> {
    return this.http.get<{body: T}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
    ).pipe(map((v)=> {
      return v.body;
    }));
  }

  query(params: GoldShopsQueryParams): Observable<QueryResult<T>> {
    return this.http.post<QueryResult<T>>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`,
        params,
    );
  }

  update<T>(id: string, product: Partial<T>) {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
        product,
    );
  }

  delete(id: string): Observable<GoldShopApiResponse> {
    return this.http.delete<GoldShopApiResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
    );
  }
}


@Injectable({
  providedIn: 'root',
})
export class GoldShopBranchService extends GenericGoldService<GoldShop> {
  constructor() {
    super('shop', 'shops');
  }
}


@Injectable({
  providedIn: 'root',
})
export class GoldCategoryService extends GenericGoldService<GoldCategory> {
  constructor() {
    super('category', 'categories');
  }
}

@Injectable({
  providedIn: 'root',
})
export class GoldCartService extends GenericGoldService<GoldCart> {
  constructor() {
    super('cart', 'carts');
  }
}
