<div class="login-container flex-row center">
  <mat-card appearance="outlined" class="flex-col">
    <mat-card-header>
      <mat-card-title>
        <div class="mat-headline-5">
          Panel Admin Indosuara
        </div>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="flex-col gap-1rem">
      <form class="flex-col" [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
        <div class="flex-row gap-10px start-center">
          <mat-icon>email</mat-icon>
          <mat-form-field class="flex-1">
            <input matInput placeholder="E-mail" aria-label="E- mail"
              formControlName="email" #email>
            <mat-error [input]="email" [group]="loginForm" appFieldError="invalid">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row gap-10px start-center">
          <mat-icon matPrefix>vpn_key</mat-icon>
          <mat-form-field class="flex-1">
            <input matInput placeholder="Password" aria- label="Password" type="password"
              formControlName="password" #password>
            <mat-hint>Minimum 8 characters</mat-hint>
            <mat-error [input]="password" [group]="loginForm"
              [appFieldError]="['required', 'minlength', 'maxlength']">
            </mat-error>
          </mat-form-field>
        </div>
        <div class="flex-row margin-top">
          <div *ngIf="loginError" class="mat-caption error">{{ loginError }}</div>
          <div class="flex-spacer"></div>
          <button mat-raised-button type="submit" color="primary"
            [disabled]="loginForm.invalid">
            Login
          </button>
        </div>
      </form>
      <mat-divider></mat-divider>
      <button
        mat-raised-button
        type="button"
        color="primary"
        matTooltip="for @indosuara.net, or ask admin to add external domain."
        (click)="loginWithGoogle()">
        Sign in With Google
      </button>

    </mat-card-content>
  </mat-card>

</div>
