import {Component} from '@angular/core';

@Component({
  selector: 'indosuara-manager',
  styles: [
    `
      mat-toolbar {
        gap: 0.5rem;
      }

      .active-link {
        font-weight: bold;
        border-bottom: 2px solid #005005;
      }
    `,
  ],
  template: `
    <mat-toolbar color="accent">
      <a mat-button
          routerLink="/manager/home"
          routerLinkActive="active-link">
        Manager's Dashboard
      </a>
      <a mat-button routerLink="/manager/users" routerLinkActive="active-link">
        User Management
      </a>
      <span class="flex-spacer"></span>
      <button
        mat-mini-fab
        routerLink="/inventory"
        matTooltip="Inventory"
        aria-label="Inventory"
      >
        <mat-icon>list</mat-icon>
      </button>
      <button
      mat-mini-fab
      routerLink="/sales"
      matTooltip="Sales"
      aria-label="Sales">
        <mat-icon>shopping_cart</mat-icon>
      </button>
    </mat-toolbar>
    <router-outlet></router-outlet>
  `,
})

/**
 * manager's component
 */
export class ManagerComponent {
}
