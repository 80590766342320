import {Validators} from '@angular/forms';

export const RequiredValidation = [Validators.required];
export const RequiredValidation2 = Validators.required;
export const OptionalTextValidation = [
  Validators.minLength(3), Validators.maxLength(50),
];

export const RequiredTextValidation = OptionalTextValidation
    .concat([Validators.required]);

export const OneCharValidation = [
  Validators.minLength(1), Validators.maxLength(1),
];

export const EmailValidation = [
  Validators.required,
  Validators.email,
];

export const PasswordValidation = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(50),
];

export const USAZipCodeValidation = [
  Validators.required,
  Validators.pattern(/^\d{5}(?:[-\s]\d{4})?$/),
];

export const USAPhoneNumberValidation = [
  Validators.required,
  Validators.pattern(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/),
];

export const ProductCategoriesValidation = [
  Validators.required,
  Validators.minLength(4),
  Validators.maxLength(100),
  Validators.pattern(/^(\d{13}),([^,\r\n]*)$/),
];

export const ImageUrlValidation = [
  Validators.required,
  Validators.pattern(/(https?:\/\/.*\.(?:png|jpg))/i),
];

export const RequiredQuantityValidation = [
  Validators.required,
  Validators.min(0),
  Validators.max(1000),
  Validators.pattern(/^(0|[1-9][0-9]*)$/),
];

export const PriceRequiredValidation = [
  Validators.required,
  Validators.min(1),
  Validators.max(999999),
  Validators.pattern(/^(0|[1-9][0-9]*)$/),
];

export const DescriptionTextValidation = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(1000),
];

export const LocalTaiwanPhoneNumberValidation = [
  Validators.required,
  Validators.pattern(/^\d{10}$/),
];

export const AddressValidation = [
  Validators.required,
  Validators.minLength(8),
  Validators.maxLength(150),
];

export const ThreeCharValidation=[
  Validators.required,
  Validators.pattern(/^\d{3}$/),
];
