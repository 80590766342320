<div class="container">
  <indosuara-staff-table
    (selected)="staffSelected($event)"
    (refresh)="deselect()"
  ></indosuara-staff-table>

  <button mat-raised-button routerLink="form" color="primary">
    Create New Staff Account
  </button>
  <div *ngIf="selectedStaff$ | async as staff">
    <indosuara-staff-view [staff]="staff">
      <div actions>
        <button mat-button (click)="deselect()">Close</button>
      </div>
    </indosuara-staff-view>
  </div>
</div>

<ng-template #loading>
  <div>
    Loading...
  </div>
</ng-template>
