import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {first, map, Observable, switchMap, timer} from 'rxjs';
import {GenericGoldService, GoldShopsQueryParams, QueryResult} from './gold-generic-service';
import {GoldPaymentChannel} from './gold-payment-channel.service';
import {GoldProduct, GoldShopResponse, Variation} from './gold-product.service';
import {GoldShippingChannel} from './gold-shipping-channel.service';
import {GoldShopApiResponse} from './kyc/user-verification.service';


export type OrderStatus =
'waitingForPayment' |
'pending' |
'shipped' |
'completed' |
'cancelled' |
'rejected' |
'returned';
export interface GoldOrder{
  id: string
  customerId?: string | null
  items?: OrderItem[]
  paymentMethodDetail?:Partial<GoldPaymentChannel>
  shippingMethodDetail?:Partial<GoldShippingChannel>
  shippingMethod?: string | null
  shippingCost?:string|number
  name?: string | null
  phoneNumber?: string | null
  address?: string | null
  addressImage?:string |null
  paymentMethod?: string | null
  totalPrice?: string | number
  note?: string | null
  createdAt?: string | null
  createdBy?: string | null
  updatedAt?: string | null
  updatedBy?: string| null
  version?: string | null
  itemInfos?: itemInfos[]
  discounts?:OrderDiscount[]
  paymentStatus?: string | null
  orderStatus?: OrderStatus | null
  isDeleted?: string | null
  promoCode?: string | null;
  installmentId?: string | null;
  installmentVersion?: string | null;
  installmentAmount?: number[] | null;
  paymentPlans?: PaymentPlan[] | null;
  cancelAt?: string | null;
  cancelBy?: string | null;
  reason?: string | null;
  rejectedAt?: string | null;
  rejectedBy?: string | null;
  returnedAt?: string | null;
  returnedBy?: string | null;
  shippingNumber?: string | null;
  shippedAt?: string | null;
  shippedBy?: string | null;
  completedAt?: string | null;
  completedBy?: string | null;
  paidAt?: string | null;
  paidBy?: string | null;
}

export interface PaymentPlan {
  amount?: number;
  deadline?: string;
}

export interface OrderDiscount {
  id: string
  name:string
  amount: number
}

export interface itemInfos{
  orderItem: OrderItem,
  product: GoldProduct,
  variation: Variation,
}

export interface OrderItem{
  shopId: string
  productId: string
  variationId: string
  quantity: number
  orderPrice: number
  priceBeforeDiscount: number | null
}

export interface GoldOrderUpdateForm {
  address?: string| null
  shippingNumber?: string | null
  reason?: string | null
}


export interface GoldOrderShippedForm {
  shippingNumber?: string | null
}

export interface CancelOrderForm {
  reason?: string | null
}

export interface RejectOrderForm {
  reason?: string | null
}

export interface DeleteOrderForm {
  reason?: string | null
}

export type OrderAction = 'cancel' | 'undo-cancel' |
'shipped' | 'undo-shipped' |
'completed' | 'undo-completed' |
'rejected' | 'undo-rejected' |
'returned' | 'undo-returned' | 'undo-delete';

@Injectable({
  providedIn: 'root',
})
export class GoldOrderService extends GenericGoldService<GoldOrder> {
  constructor() {
    super('order', 'orders');
  }

  override get(id: string): Observable<GoldOrder> {
    return super.get(id).pipe(
        map((response)=> {
          return this.transformProductPrice(response);
        }),
    );
  }

  override query(params: GoldShopsQueryParams):
  Observable<QueryResult<GoldOrder>> {
    return super.query(params).pipe(
        map((resp)=> {
          const response: QueryResult<GoldOrder> ={
            body: resp.body?.map((p)=> this.transformProductPrice(p)),
            totalData: resp.totalData,
          };
          return response;
        }),
    );
  }

  override update(id: string, data: Partial<GoldOrderUpdateForm>)
  : Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
        data,
    );
  }

  payOrder(id:string, params?:{
    paymentMethodId?: string
    payAmount?: number
  }):
  Observable<GoldShopApiResponse> {
    const initTime = moment().toISOString();
    return this.http.put<GoldShopResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id + '/pay',
        params ?? {},
    ).pipe( switchMap((res) =>
      this.pollForUpdatedPickupBatch(id, initTime, res)));
  }


  cancelOrder(
      id:string,
      data: CancelOrderForm,
  ):
      Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'cancel', data);
  }
  undoCancelOrder(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-cancel');
  }

  setOrderShipped(id:string, data: Partial<GoldOrderShippedForm>):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'shipped', data);
  }

  undoOrderShipped(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-shipped');
  }

  setOrderCompleted(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'completed');
  }

  undoOrderCompleted(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-completed');
  }

  setOrderRejected(id:string, data: Partial<RejectOrderForm>):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'rejected', data);
  }

  undoOrderRejected(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-rejected');
  }

  setOrderReturned(id:string, data: Partial<RejectOrderForm>):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'returned', data);
  }

  undoOrderReturned(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-returned');
  }

  undoOrderDeleted(id:string):
  Observable<GoldShopApiResponse> {
    return this.setOrder(id, 'undo-delete');
  }

  setOrderDeleted(id:string):
  Observable<GoldShopApiResponse> {
    return this.http.delete<GoldShopResponse>(
        this.goldshopApiUrl +
        `/gold/api/v0/staff/${this.name}/` + id,
    );
  }

  private setOrder(
      id: string,
      action: string,
      data?: Partial<GoldOrderUpdateForm>):
Observable<GoldShopApiResponse> {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl +
      `/gold/api/v0/staff/${this.name}/` + id + '/' + action,
        data,
    );
  }


  private pollForUpdatedPickupBatch(
      id: string,
      initTime: string,
      response: GoldShopResponse): Observable<GoldShopResponse> {
    const pollingInterval = 1000;
    const maxAttempts = 5;
    let attempts = 0;

    return timer(0, pollingInterval).pipe(
        switchMap(() => this.get(id)),
        first((response) => {
          attempts++;
          const isDataUpdated = moment(initTime).isBefore(response.updatedAt);
          return isDataUpdated || attempts >= maxAttempts;
        }, true),
        map(()=> response),

    );
  }

  private transformProductPrice(product: GoldOrder) {
    const pr = _.cloneDeep(product);

    pr.totalPrice = this.convertPrice(pr.totalPrice);
    pr.shippingCost=this.convertPrice(pr.shippingCost);

    pr.itemInfos?.forEach((item) => {
      item.orderItem.orderPrice = this.convertPrice(item.orderItem.orderPrice );
    });

    pr.discounts?.forEach((discount)=>{
      discount.amount=this.convertPrice(discount.amount);
    });

    pr.items?.forEach((item)=>{
      item.orderPrice = this.convertPrice(item.orderPrice);
      item.priceBeforeDiscount = this.convertPrice(item.priceBeforeDiscount);
    });
    return pr;
  }

  private convertPrice(price?: string | number | null): number {
    return ((price??0) as number) / 100000;
  }
}
