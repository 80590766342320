import {CommonModule} from '@angular/common';
import {FieldErrorDirective} from './field-error.directive';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule],
  declarations: [FieldErrorDirective],
  exports: [FieldErrorDirective],
})
/**
 * Field Error Module to simplify displaying error messages in user input form
 */
export class FieldErrorModule {}
