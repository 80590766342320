<div class="container">
  <h1>Create New Staff Account</h1>
  <p>Staff user later need to self verify their own email.</p>
  <form
    class="staff-form"
    [formGroup]="formGroup2"
    (ngSubmit)="submit()"
    autocomplete="chrome-off"
  >
    <mat-form-field appearance="outline">
      <mat-label>E-mail</mat-label>
      <input
        matInput
        placeholder="ex. test@indosuara.net"
        aria-label="e-mail"
        formControlName="email"
        #email
      />
      <mat-error
        [input]="email"
        [group]="formGroup2"
        [appFieldError]="['required', 'minlength', 'maxlength', 'invalid']"
      >
      </mat-error>
      <mat-hint>ex. any&#64;mail.com</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Staff's name</mat-label>
      <input
        matInput
        placeholder="ex. Budi"
        aria-label="Staff Name"
        formControlName="name"
        #name
      />
      <mat-error
        [input]="name"
        [group]="formGroup2"
        [appFieldError]="['required', 'minlength', 'maxlength', 'invalid']"
      >
      </mat-error>

      <mat-hint
        >This name will be used as an identifier for an action.</mat-hint
      >
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        matInput
        placeholder="*****"
        aria-label="Password"
        autocomplete="new-password"
        formControlName="password"
        [type]="hide ? 'password' : 'text'"
        #password
      />
      <mat-icon matSuffix (click)="hide = !hide">{{
        hide ? 'visibility_off' : 'visibility'
      }}</mat-icon>
      <mat-error
        [input]="password"
        [group]="formGroup2"
        [appFieldError]="['required', 'minlength', 'maxlength', 'invalid']"
      >
      </mat-error>
      <mat-hint>ex. password</mat-hint>
    </mat-form-field>
    <button
      mat-raised-button
      class="custom-width"
      type="submit"
      color="primary"
      [disabled]="formGroup2.invalid || formGroup2.disabled"
    >
      <span *ngIf="!isSubmitting">Create New User</span>
      <span *ngIf="isSubmitting" class="spinner">
        <mat-spinner diameter="28"></mat-spinner>
      </span>
    </button>
  </form>
</div>
