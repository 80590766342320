import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Status} from './order.enum';

export interface TimeStamp {
  seconds: number;
  nanoseconds: number;
}

export interface OrderItem {
  productId: string;
  productName: string;
  sku: string;
  type: string;
  color?: string;
  size?: string;
  images?: string[];
  price: number;
  quantity: number;
}

export interface Order {
  id: string;
  transactionId: string;
  status: Status;
  customerId: string;
  customer: string;
  customerAddress?: string;
  customerAddressImageUrl?: string;
  customerPhone: string;
  legacyTransactionNo?: string;
  packageNo?: string;
  items: OrderItem[];
  shippingMethod: string;
  shippingCost: number;
  createdAt: TimeStamp;
  acceptedAt?: TimeStamp;
  shippedAt?: TimeStamp;
  lastUpdatedAt: TimeStamp
  lastUpdatedBy: string;
}

export interface OrderHistory {
  eventId: string;
  createdAt: TimeStamp;
  createdBy: string;
  order: Order;
}

export interface OrderResponse {
  status: string;
  code: number;
  message: string;
}

export interface OrderEditableFields {
  transactionNo?: string;
  customerAddress?: string;
}

export interface OrderQueryParameters {
  phoneNumber?: string;
  limit?: number;
  startAfter?: TimeStamp;
  statuses?: string[];
}

@Injectable({
  providedIn: 'root',
})
/**
 * Order Service
 */
export abstract class OrderService {
  abstract getNewOrders(): Observable<Order[]>;
  abstract getPendingOrders(): Observable<Order[]>;
  abstract getShippedOrders(): Observable<Order[]>;

  abstract getOrder(id:string): Observable<Order>;
  abstract getOrderHistory(id:string): Observable<OrderHistory[]>;

  abstract acceptOrder(id:string): Observable<OrderResponse>;
  abstract shipOrder(id:string, packageNo:string): Observable<OrderResponse>;
  abstract cancelOrder(id:string): Observable<OrderResponse>;
  abstract finishOrder(id:string): Observable<OrderResponse>;

  abstract updateAddress(id:string, address:string): Observable<OrderResponse>;
  abstract updateTransactionNo(
    id:string, transactionNo:string): Observable<OrderResponse>;

  abstract query(params:OrderQueryParameters): Observable<Order[]>;
  abstract queryByPhone(phone: string): Observable<Order[]>;
  abstract queryByName(name: string): Observable<Order[]>;
  abstract queryByTransactionId(id: string): Observable<Order[]>;
}
