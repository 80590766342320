import {Injectable} from '@angular/core';
import {GenericGoldService} from './gold-generic-service';

export interface GoldPartnerVoucher{
  id: string
  code: string
  amount: string
  isDeleted: string,
  enabled: string,
  createdAt: string
  updatedAt: string
  createdBy: string
  updatedBy: string
}


@Injectable({
  providedIn: 'root',
})
export class GoldPartnerVoucherService extends
  GenericGoldService<GoldPartnerVoucher> {
  constructor() {
    super('partner-voucher', 'partner-vouchers');
  }
}
