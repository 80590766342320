
<div class="container">
  <div class="head" style="display: flex; gap: 1rem; padding: 1rem;">
    <button mat-stroked-button (click)="newBanner()">New Banner</button>
    <!-- CheckBox Style -->
    <!-- <div>
      <mat-checkbox 
        [checked]="isActiveFilterChecked$.value"
        (change)="isActiveFilterChecked$.next(!isActiveFilterChecked$.value)"
        >Is Active</mat-checkbox>
    </div> -->
  </div>
    <div class="filter" [formGroup]="filter">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select formControlName="isActive">
          <mat-option value="Y">Active</mat-option>
          <mat-option value="N">Not Active</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</div>



<ng-container *ngIf="banners$ | async as banners">
  <mat-paginator
  [length]="banners.totalData"
  [pageSize]="pageSize"
  [pageIndex]="pageNumber"
  [pageSizeOptions]="[5,10,20]"
  (page)="onPaginateChange($event)"
  ></mat-paginator>
  <div class="banner-list">
    <div class="banner-card" *ngFor="let banner of banners.body">
      <div class="image-container">
        <img [src]="banner.imageUrl" [alt]="banner.id" alt="Arc Back Side">
        <div class="image-action-buttons" >
          <button class="white-bg" mat-icon-button (click)="changeImage(banner.id)" matTooltip="click to change image">
            <mat-icon>change_circle</mat-icon>
          </button>
          <a class="white-bg" mat-icon-button [href]="banner.imageUrl" target="_blank" matTooltip="click to open image in new tab">
            <mat-icon>open_in_new</mat-icon>
          </a>
        </div>
      </div>
      <div>
        <div style="font-size: small; opacity: 0.7;" matTooltip="this link opens when user taps on in app carousel banner">
          <div>
            link to <span style="cursor: pointer; color: blue;" (click)="changeLink(banner.id)">edit</span>
          </div>
          <a [href]="banner.url" target="_blank">{{banner.url}}</a>
        </div>
      </div>
      <div>
        <mat-slide-toggle
        [checked]="banner.isActive ==='Y'"
        (change)="bannerActiveChanged(banner.id, $event)"
        matTooltip="when active, banner is visible in app"
        >
          Active
        </mat-slide-toggle>
      </div>
    </div>
  </div>
</ng-container>
