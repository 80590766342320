export interface Staff {
  emailVerified: boolean;
  email: string;
  uid: string;
  photoUrl: string;
  name: string;
  disabled: boolean;
  creationTime: string;
  lastRefreshTime: string;
  lastSignInTime: string;
  phoneNumber: string;
  staff: boolean;
  manager: boolean;
  idpAgentCode?: string;
  permissions: string[];
}
