import {RouterModule, Routes} from '@angular/router';


import {NgModule} from '@angular/core';
import {ManagerHomeComponent} from './manager-home/manager-home.component';
import {ManagerComponent} from './manager.component';

import {StaffFormComponent} from './staff-form/staff-form.component';
import {
  UserManagementComponent,
} from './user-management/user-management.component';

const routes: Routes = [
  {
    path: '',
    component: ManagerComponent,
    children: [
      {path: '', redirectTo: '/manager/home', pathMatch: 'full'},
      {
        path: 'home',
        component: ManagerHomeComponent,
      },
      {
        path: 'users/form',
        component: StaffFormComponent,

      },
      {
        path: 'users',
        component: UserManagementComponent,
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
/**
 * Routing Module for manager's page
 */
export class ManagerRoutingModule {}
