import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, map} from 'rxjs';
import {GenericGoldService, GoldShopsQueryParams, QueryResult} from './gold-generic-service';


export interface Installment{
  id: string
  name: string
  feeType : 'percentage' | 'fixed'
  fee: number
  duration: number // month
  isActive: 'Y' | 'N'
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface DeleteAndUpdateInstallmentResponse {
  message: string
}


@Injectable({
  providedIn: 'root',
})
export class InstallmentSettingsService extends
  GenericGoldService<Installment> {
  constructor() {
    super('installment', 'installments');
  }


  override get(id: string): Observable<Installment> {
    return this.http.get<{body: Installment}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
    ).pipe(map((v)=> {
      return this.transform(v.body);
    }));
  }


  private transform(v:Installment) {
    const data = _.cloneDeep(v);
    data.feeType;
    if (data.feeType == 'fixed') {
      data.fee = data.fee / 100000;
    }
    return data;
  }

  override query(params: GoldShopsQueryParams):
  Observable<QueryResult<Installment>> {
    return this.http.post<QueryResult<Installment>>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`,
        params,
    ).pipe(map((v)=>{
      v.body = v.body?.map((v)=> this.transform(v));
      return v;
    }));
  }

  override delete(id: string):
  Observable<DeleteAndUpdateInstallmentResponse> {
    return this.http.delete<DeleteAndUpdateInstallmentResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
    );
  }

  override update(id: string, product: Partial<Installment>)
   : Observable<DeleteAndUpdateInstallmentResponse> {
    return this.http.put<DeleteAndUpdateInstallmentResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/` + id,
        product,
    );
  }
}
