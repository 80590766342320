import {CustomerProfile} from './customer-profile.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 *
 */
export abstract class CustomerProfileService {
  abstract queryByEmail(email:string): Observable<CustomerProfile[]>;
  abstract queryByPhone(phone:string): Observable<CustomerProfile[]>;
  abstract queryByName(name:string): Observable<CustomerProfile[]>;
  abstract get(uid:string): Observable<CustomerProfile>;
  abstract softBanUser(uid:string): Observable<any>;
  abstract unSoftBanUser(uid:string): Observable<any>;
}
