import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from '../config';


export interface GoldShopKycListParams {
  pageSize?: number
  pageNumber?: number
  status?: GoldShopKycVerificationStatus[] | null
  arcNo?: string | null
  name?: string | null
  phoneNumber?: string | null
  isUpdateMember?: 'Y' | 'N' | '' | null
}

export interface GoldShopKycForms{
  body: GoldShopKycForm[];
  totalData: number;
}

export type GoldShopKycUserGender = 'male' | 'female' | ''

export type GoldShopKycArcType = 'student' | 'work' | 'whiteCollar'
| 'permanent' | 'missionaries' |
'marriage' | 'other'

export type GoldShopKycVerificationStatus = 'inReview' |
'needRevision' | 'verified' | 'rejected' | ''

export type GoldShopKycProblemType = 'arcFrontProblem' |
'arcBackProblem'

export type GoldShopKycForm = GoldShopKycBasicInfo & GoldShopKycUpdatableInfo;


export interface GoldShopKycBasicInfo{
  id: string;
  userId: string;
  phoneNumber: string;
  status: GoldShopKycVerificationStatus;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  problems: GoldShopKycProblemType[];
  problemMessage: string;
  isUpdateMember?: 'Y' | 'N' | '';
  parsedText?: string;
  ipAddress?: string;
  potentialPhoneNumber?: string[];
}

export interface GoldShopKycUpdatableInfo {
  arcFrontImage: string;
  arcBackImage: string;
  selfieImage: string;
  name: string;
  userSignatureImage: string;
  arcNo: string;
  dateOfBirth: string;
  sex: GoldShopKycUserGender;
  address: string;
  arcType: GoldShopKycArcType;
  arcOld: string;
  arcExpiredDate?: string;
  arcIssueDate?: string;
  attachments?: string[];
  arcBarcodeNumber: string;
  nationality: string;
  institution?: string;
  passportNumber: string;
}

export type GoldShopKycUpdatableInfoKeys = keyof GoldShopKycUpdatableInfo

export interface GoldShopApiResponse{
  message?: string;
  error?: string;
}


export interface GoldShopRequestRevisionForm{
  problems: GoldShopKycProblemType[];
  problemMessage: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserVerificationService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) {}

  listKycForms(params:GoldShopKycListParams): Observable<GoldShopKycForms> {
    return this.http.post<GoldShopKycForms>(
        this.goldshopApiUrl + '/gold/api/v0/staff/verification/kycs',
        params,
    );
  }

  getKycFormDetails(id: string): Observable<GoldShopKycForm> {
    return this.http.get<{body: GoldShopKycForm}>(
        this.goldshopApiUrl + '/gold/api/v0/staff/verification/kyc/' + id,
    ).pipe(map((v)=> v.body));
  }

  acceptUser(id: string): Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopApiResponse>(
        this.goldshopApiUrl +
        `/gold/api/v0/staff/verification/kyc/${id}/approve`,
        {},
    );
  }

  rejectUser(id: string): Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopApiResponse>(
        this.goldshopApiUrl +
        `/gold/api/v0/staff/verification/kyc/${id}/reject`,
        {},
    );
  }

  requestRevision(id: string, data: GoldShopRequestRevisionForm)
  :Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopApiResponse>(
        this.goldshopApiUrl +
        `/gold/api/v0/staff/verification/kyc/${id}/request-revision`,
        data,
    );
  }

  editUserInfo(id: string, data: Partial<GoldShopKycUpdatableInfo>):
  Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopApiResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/verification/kyc/${id}`,
        data,
    );
  }

  getArcTypes():
    Observable<GoldShopKycArcType[]> {
    return this.http.get<{body: GoldShopKycArcType[]}>(
        this.goldshopApiUrl + '/gold/api/v0/staff/verification/arc-types',
    ).pipe(map((v)=> v.body));
  }

  getARCTypesNames(): Record<GoldShopKycArcType, string> {
    return {
      student: 'Student-Internship 就學-產學合作專班',
      work: 'Worker 外勞/移工',
      whiteCollar: 'White Collar 應聘',
      permanent: 'Permanent 永久居留證',
      missionaries: 'Missionaries',
      marriage: 'Marriage 依親 - 夫/妻',
      other: 'Other 其他',
    };
  }

  uploadImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<GoldShopApiResponse & {url:string}>(
        `${this.goldshopApiUrl}/gold/api/v0/staff/verification/upload-image`,
        formData,
    ).pipe(map((v)=>v.url));
  }
}
