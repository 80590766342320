import {Component, Input} from '@angular/core';

import {Staff} from '../staff/staff.interface';

@Component({
  selector: 'indosuara-staff-view [staff]',
  templateUrl: './staff-view.component.html',
  styleUrls: ['./staff-view.component.css'],
})
export class StaffViewComponent {
  @Input() staff!: Staff;
}
