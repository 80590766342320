import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '@indosuara/auth';
import {Observable, tap} from 'rxjs';
import {NavigationService} from '../navigation-menu/navigation-service.service';
import {SideNavNode} from '../navigation-menu/sidenav-data';

@Component({
  selector: 'indosuara-home',
  styleUrls: ['./home.component.css'],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  constructor(
    private authService: AuthService,
    private navService: NavigationService,
    private router: Router,
  ) {
    this.user$ = this.authService.authState().pipe(
        tap((user) => {
          if (!user) {
            this.router.navigate(['/login']);
          }
        }),
    );
    this.accessList$ = this.navService.navigations();
  }
  user$;

  accessList$: Observable<SideNavNode[]>;
}
