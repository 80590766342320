<mat-form-field appearance="fill">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Email or Name" #input>
</mat-form-field>
<div *ngIf="loading$ | async as loading">
  <div *ngIf="loading ; else loadingloader">
  </div>
</div>

<div *ngIf="dataSource.data.length; else empty">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="email">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Email </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let staff"> {{staff.email}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Name </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let staff"> {{staff.name }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="role">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Role </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let staff"> {{staff.staff ? staff.manager ? 'Manager': 'Staff' : 'Disabled' }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="lastSignInTime">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> lastSignInTime </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let staff"> {{staff.lastSignInTime | date : 'short'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="action">
      <mat-header-cell mat-header-cell *matHeaderCellDef > Action </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let staff">
        <button mat-icon-button [matMenuTriggerFor]="more" [matMenuTriggerData]="{staff: staff}" matTooltip="Click to show more actions.">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple (click)="rowSelected(row)" *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </table>
</div>
<mat-toolbar>
  <mat-toolbar-row>
    <button mat-icon-button (click)="refresh$.next(null)"
    matTooltip="Refresh Table Data">
      <mat-icon title="Refresh">refresh</mat-icon>
    </button>
    <span class="flex-spacer"></span>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]">
    </mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>




<ng-template #empty>
  <p class="empty-message">Retreiving data...</p>
</ng-template>

<ng-template #loadingloader>
  <div>Loading...</div>
</ng-template>

<mat-menu #more="matMenu">
  <ng-template matMenuContent let-staff="staff">
    <indosuara-staff-action-menu-item [staff]="staff" (refresh)="invokeRefresh($event)" ></indosuara-staff-action-menu-item>
  </ng-template>
</mat-menu>
