import {Directive, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';

import {environment} from '../../environments/environment';

/**
 * usage: <div *indosuaraHideProd></div>
 */
@Directive({
  selector: '[indosuaraHideProd]',
})
/**
 * IndosuaraHideProdDirective
 */
export class IndosuaraHideProdDirective implements OnInit {
  constructor(
      private templateRef: TemplateRef<any>,
      private viewContainerRef: ViewContainerRef) { }

  ngOnInit(): void {
    if (!environment.production) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
