import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

const CUSTOMEDATE_FORMATS = {
  parse: {
    dateInput: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD', 'YYMMDD'],
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: CUSTOMEDATE_FORMATS},
  ],
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <mat-form-field appearance="fill" style="width: 100%;">
        <mat-label>{{ data.content }}</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="selectedDate" />
        <mat-hint>YYYY/MM/DD</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-spacer"></span>
      <button mat-button mat-dialog-close>{{ data.cancelText || 'Cancel' }}</button>
      <button mat-button color="primary" (click)="onSubmit()">
        {{ data.okText || 'OK' }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DatePickerDialogComponent {
  selectedDate: Date | undefined;

  constructor(
    public dialogRef: MatDialogRef<DatePickerDialogComponent, Date>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onSubmit() {
    this.dialogRef.close(this.selectedDate);
  }
}
