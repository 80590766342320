import {ImageDialogComponent, ImageDialogUploadFunction} from './image-dialog.component';

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageUploadDialogService {
  constructor(
    private dialog: MatDialog,
  ) { }

  openImageUploadDialog(
      opt: ImageDialogUploadFunction,
  ) : Observable<string |undefined> {
    const dialogRef = this.dialog.open<
    ImageDialogComponent,
    ImageDialogUploadFunction,
    string
    >(ImageDialogComponent, {
      width: '350px',
      data: opt,
    });

    return dialogRef.afterClosed();
  }
}
