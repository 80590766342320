import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GenericGoldService} from './gold-generic-service';
import {GoldShopResponse} from './gold-product.service';

export interface GoldPoint{
  id: string
  userId: string
  relateId: string
  point: number
  detail: string
  createdAt: string
  createdBy: string
}

export interface GoldPoints{
  body: GoldPoint[]
  message: string
  totalData: number
}

export interface TotalPoint{
  totalPoint: number
}

export interface PointGetByUserResponse {
  body: TotalPoint
  message: string
}

export interface PointConfig {
  id: string;
  registrationPoint: number;
  purchasePoint: number;
  referralPoint: number;
  createdAt: Date;
  createdBy: string;
}

export interface PointConfigRequest {
  registrationPoint?: number;
  purchasePoint?: number;
  referralPoint?: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoldPointService extends
  GenericGoldService<GoldPoint> {
  constructor() {
    super('point-mutation', 'point-mutations');
  }

  getPointByUser(id: string): Observable<TotalPoint> {
    return this.http.get<PointGetByUserResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/point/${id}`,
    ).pipe(map((v)=> {
      return v.body;
    }));
  }

  createPointConfig(params: PointConfigRequest):Observable<GoldShopResponse> {
    return this.http.post<GoldShopResponse>(
        this.goldshopApiUrl+'/gold/api/v0/staff/point-config',
        params,
    );
  }

  getLatestPointConfig():Observable<PointConfig> {
    return this.http.get<{body: PointConfig}>(
        this.goldshopApiUrl+'/gold/api/v0/staff/point-config',
    ).pipe(map((v)=>v.body));
  }
}
