import {Observable, map} from 'rxjs';
import {SIDENAV_DATA, SideNavNode} from './sidenav-data';

import {Injectable} from '@angular/core';
import {AuthService, User} from '@indosuara/auth';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private authSvc: AuthService) {}

  navigations(): Observable<SideNavNode[]> {
    return this.authSvc.authState()
        .pipe(
            map((user)=>{
              return this.filterSideNavByPermission(
                  SIDENAV_DATA, user);
            }),
        );
  }

  private filterSideNavByPermission(
      nodes: SideNavNode[], user: User | null): SideNavNode[] {
    return nodes
        .map((nav) => {
          const filteredChildren = nav.children ?
          this.filterSideNavByPermission(nav.children, user) :
          [];

          const isProd = this.getProductionStatus(nav);
          const hasPermission = this.checkPermissions(user, nav);

          if (isProd && hasPermission) {
            return {
              ...nav,
              children: filteredChildren.length > 0 ?
                filteredChildren : undefined,
            };
          }
          return null;
        })
        .filter((nav) => nav !== null) as SideNavNode[];
  }


  getProductionStatus(nav:SideNavNode):boolean {
    if (!nav.status) {
      return true;
    }
    if ( environment.production ) {
      return nav.status === 'production';
    }
    return nav.status === 'development' || nav.status === 'production';
  }

  checkPermissions(user:User |null, nav:SideNavNode):boolean {
    let permissionsList: string[] = [];
    user?.permissions?.forEach((v)=>{
      permissionsList.push(v.split(':')[0]);
      permissionsList.push(v);
    });
    if (user?.manager) {
      permissionsList.push('superadmin');
    }
    permissionsList = permissionsList.filter(
        (value, index, array) => array.indexOf(value) === index,
    );
    return nav.requiredPermissions?.every((
        value)=> permissionsList.includes(value)) ?? true;
  }
}
