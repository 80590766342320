import {Injectable} from '@angular/core';
import {GenericGoldService} from './gold-generic-service';


export interface StockLocation{
  id: string
  name:string
}

@Injectable({
  providedIn: 'root',
})
export class StockLocationService extends GenericGoldService<StockLocation> {
  constructor() {
    super('stock-location', 'stock-locations');
  }
}
