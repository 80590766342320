import {Observable, catchError, filter, map} from 'rxjs';
import {
  Order,
  OrderHistory,
  OrderItem,
  OrderQueryParameters,
  OrderResponse,
  OrderService,
} from './order.service';
import {
  checkAppError,
  transformError,
} from '../../../common/common';

import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Injectable} from '@angular/core';
import {Status} from './order.enum';

export interface FirestoreOrderDoc {
  id: string,
  createdAt: {
    seconds: number;
    nanoseconds: number;
  },
  lastUpdatedAt?: {
    seconds: number;
    nanoseconds: number;
  },
  acceptedAt?: {
    seconds: number;
    nanoseconds: number;
  },
  shippedAt?: {
    seconds: number;
    nanoseconds: number;
  },
  customerAddress?: string,
  customerAddressImageUrl?: string,
  customerId: string;
  customerName: string;
  customerPhone: string;
  lastUpdatedBy: string;
  shippingCost: number;
  shippingMethod: string;
  status: string;
  items: {
    [sku:string]:FirestoreOrderItem;
  },
  packageNo?:string;
  legacyTransactionNumber?:string;
  transactionId?:string;
}

interface FirestoreOrderItem {
  name: string;
  price: number;
  product: {
    id: string;
    images: string[];
    name: string;
  }
  quantity: number;
}


@Injectable({
  providedIn: 'root',
})
/**
 *
 */
export class FirebaseOrderService extends OrderService {
  constructor(
    private afn: AngularFireFunctions,
    private afs: AngularFirestore,
  ) {
    super();
  }

  query(params: OrderQueryParameters): Observable<Order[]> {
    const statuses = params.statuses ?? [
      'new', 'pending', 'shipped', 'cancel', 'completed'];
    const result = this.afs
        .collection<FirestoreOrderDoc>('orders',
            (ref) => ref.where('customerPhone', '==', params.phoneNumber)
                .where('status', 'in', statuses)
                .orderBy('createdAt', 'desc'))
        .valueChanges({idField: 'id'})
        .pipe(map((ordersCol) =>
          this.convertOrderListFromCollection(ordersCol),
        ));
    return result;
  }

  queryByPhone(phone: string): Observable<Order[]> {
    const result = this.afs.collection<FirestoreOrderDoc>('orders',
        (ref) => ref.where('customerPhone', '==', phone)
            .orderBy('createdAt', 'desc'))
        .valueChanges({idField: 'id'})
        .pipe(map((data) =>
          this.convertOrderListFromCollection(data),
        ));
    return result;
  }

  queryByName(name: string): Observable<Order[]> {
    const result = this.afs.collection<FirestoreOrderDoc>('orders',
        (ref) => ref.where('customerName', '>=', name)
            .where('customerName', '<=', name + '\uf8ff'))
        .valueChanges({idField: 'id'})
        .pipe(map((data) =>
          this.convertOrderListFromCollection(data),
        ));
    return result;
  }

  queryByTransactionId(id: string): Observable<Order[]> {
    const result = this.afs.collection<FirestoreOrderDoc>('orders',
        (ref) => ref.where('transactionId', '==', id))
        .valueChanges({idField: 'id'})
        .pipe(map((data) =>
          this.convertOrderListFromCollection(data),
        ));
    return result;
  }

  getNewOrders(): Observable<Order[]> {
    return this.queryOrderCollectionWhichStatusIs('new');
  }

  getPendingOrders(): Observable<Order[]> {
    return this.queryOrderCollectionWhichStatusIs('pending');
  }
  getShippedOrders(): Observable<Order[]> {
    return this.queryOrderCollectionWhichStatusIs('shipped');
  }
  getOrder(id: string): Observable<Order> {
    return this.retreiveOrderRecordFromFirestore(id);
  }

  getOrderHistory(id: string): Observable<OrderHistory[]> {
    return this.afs
        .collection('orders')
        .doc(id)
        .collection<FirestoreOrderDoc>('orderEvents',
            (ref) => ref
                .orderBy('lastUpdatedAt', 'desc'))
        .valueChanges({idField: 'id'})
        .pipe(
            map((collection) =>{
              const orderHistory :OrderHistory[] = [];
              collection.forEach((doc) => {
                orderHistory.push(this.convertToOrderHistory(doc));
              });
              return orderHistory;
            }),
        );
  }
  private convertToOrderHistory(
      doc: FirestoreOrderDoc & { id: string; }): OrderHistory {
    const order = this.convertOrderFromDocument(doc);
    return {
      eventId: order.id,
      createdAt: order.lastUpdatedAt,
      createdBy: order.lastUpdatedBy,
      order: order,
    };
  }

  private retreiveOrderRecordFromFirestore(id: string): Observable<Order> {
    return this.afs
        .collection('orders')
        .doc<FirestoreOrderDoc>(id)
        .valueChanges({idField: 'id'})
        .pipe(
            filter(this.isDefined),
            map((doc)=>
              this.convertOrderFromDocument(doc),
            ));
  }


  private isDefined<T>(arg: T | null | undefined):
  arg is T extends null | undefined ? never : T {
    return arg !== null && arg !== undefined;
  }

  private queryOrderCollectionWhichStatusIs(status:string) {
    return this.afs
        .collection<FirestoreOrderDoc>('orders',
            (ref) => ref.where('status', '==', status)
                .orderBy('createdAt', 'desc'))
        .valueChanges({idField: 'id'})
        .pipe(map((ordersCol) =>
          this.convertOrderListFromCollection(ordersCol),
        ));
  }


  private convertOrderListFromCollection(
      ordersCol: (FirestoreOrderDoc & { id: string; })[]): Order[] {
    const orders: Order[] = [];
    ordersCol.forEach((doc) => {
      orders.push(this.convertOrderFromDocument(doc));
    });
    return orders;
  }

  private convertOrderFromDocument(
      doc: FirestoreOrderDoc & { id: string; }): Order {
    const tempStatus: Status = this.convertOrderStatus(doc.status);
    const orderItems: OrderItem[] = this.transformOrderItemsList(doc);
    const lastUpdatedAt = {
      seconds: doc.lastUpdatedAt?.seconds ?? doc.createdAt.seconds,
      nanoseconds: doc.lastUpdatedAt?.nanoseconds ?? doc.createdAt.nanoseconds,
    };
    return {
      id: doc.id,
      transactionId: doc.transactionId ?? 'Not Generated',
      status: tempStatus,
      customerId: doc.customerId,
      customerAddress: doc.customerAddress,
      customer: doc.customerName,
      customerPhone: doc.customerPhone,
      customerAddressImageUrl: doc.customerAddressImageUrl,
      items: orderItems,
      shippingMethod: doc.shippingMethod,
      shippingCost: doc.shippingCost,
      createdAt: {
        seconds: doc.createdAt.seconds,
        nanoseconds: doc.createdAt.nanoseconds,
      },
      lastUpdatedAt: lastUpdatedAt,
      lastUpdatedBy: doc.lastUpdatedBy,
      shippedAt: doc.shippedAt,
      acceptedAt: doc.acceptedAt,
      packageNo: doc.packageNo,
      legacyTransactionNo: doc.legacyTransactionNumber,
    };
  }

  private convertOrderStatus(status:string) {
    if (status == 'new') {
      return Status.New;
    }
    if (status == 'pending') {
      return Status.Pending;
    }
    if (status == 'shipped') {
      return Status.Shipped;
    }
    if (status == 'completed') {
      return Status.Done;
    }
    if (status == 'cancel') {
      return Status.Cancelled;
    }
    return Status.Unknown;
  }

  private transformOrderItemsList(
      doc: FirestoreOrderDoc & { id: string; }): OrderItem[] {
    const items: OrderItem[] = [];
    for (const key in doc.items) {
      if ({}.hasOwnProperty.call(doc.items, key)) {
        items.push(this.transformOrderItem(key, doc.items[key]));
      }
    }
    return items;
  }

  private transformOrderItem(sku: string, item: FirestoreOrderItem) {
    const orderItem: OrderItem = {
      productId: item.product.id,
      productName: item.product.name,
      sku: sku,
      type: item.name,
      price: item.price,
      quantity: item.quantity,
    };
    return orderItem;
  }

  acceptOrder(id: string): Observable<OrderResponse> {
    const endpoint = 'order-staffAcceptOrderRequest';
    const callable = this.afn.httpsCallable(endpoint);
    const response$ = callable({
      orderId: id,
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
  shipOrder(id: string, packageNo: string): Observable<OrderResponse> {
    const callable = this.afn.httpsCallable('order-staffShipOrderRequest');
    const response$ = callable({
      orderId: id,
      packageNo: packageNo,
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
  cancelOrder(id: string): Observable<OrderResponse> {
    const callable = this.afn.httpsCallable('order-staffCancelOrderRequest');
    const response$ = callable({
      orderId: id,
      reason: 'cancelled by admin',
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
  finishOrder(id: string): Observable<OrderResponse> {
    const callable = this.afn.httpsCallable('order-staffFinishOrder');
    const response$ = callable({
      orderId: id,
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
  updateAddress(id: string, address: string): Observable<OrderResponse> {
    const callable = this.afn.httpsCallable('order-staffEditOrderData');
    const response$ = callable({
      orderId: id,
      address: address,
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
  updateTransactionNo(
      id: string, transactionNo: string): Observable<OrderResponse> {
    const callable = this.afn.httpsCallable('order-staffEditOrderData');
    const response$ = callable({
      orderId: id,
      legacyTransactionNumber: transactionNo,
    }).pipe(
        map(checkAppError),
        catchError(transformError),
    );
    return response$;
  }
}
