<div class="home-container" *ngIf="(user$| async) as user; else loading">
  <div class="home-title-section">
    <h1>Indosuara Employee Console</h1>
    <p>同合順企業股份有限公司 SUNRICH ENT CO., LTD.</p>
    <mat-divider></mat-divider>
  </div>
  <div class="home-greeting-section">
    <h3>Hi, {{ user.name || user.email }}!</h3>
  </div>

  <div class="quick-access-container" *ngIf="user">
    <h2>Quick Access</h2>

    <div class="quick-access-inner" >
      <ng-container *ngIf="accessList$ | async as accessList">
        <div class="quick-access-child" *ngFor="let node of accessList">
          <mat-card appearance="outlined">
            <mat-card-title>
              {{node.name}}
            </mat-card-title>
            <mat-divider></mat-divider>
            <mat-card-content>
              <mat-nav-list>
                <a
                  mat-list-item
                  *ngFor="let page of node.children"
                  [routerLink]="page.route"
                >
                  {{ page.name }}
                </a>
              </mat-nav-list>
            </mat-card-content>
          </mat-card>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loading>
  Loading...
</ng-template>
