import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  map,
  merge,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UiService} from '@indosuara/common-dialog-ui';

import {StaffDialogService} from '../dialog-services/staff-dialog.service';
import {Staff} from '../staff/staff.interface';
import {StaffService} from '../staff/staff.service';

@Component({
  selector: 'indosuara-staff-table',
  templateUrl: './staff-table.component.html',
  styleUrls: ['./staff-table.component.css'],
})
export class StaffTableComponent implements OnInit, AfterViewInit {
  dataSource!: MatTableDataSource<Staff>;

  @Output() selected = new EventEmitter<Staff>();
  @Output() refresh = new EventEmitter();

  @ViewChild(MatPaginator, {static: false}) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;
  displayedColumns: string[] = [
    'email', 'name', 'role', 'lastSignInTime', 'action'];

  filterValue?:string;

  refresh$ = new Subject();

  staffs$!: Observable<Staff[]>;

  readonly isLoadingResults$ = new BehaviorSubject(true);

  appPermissions$: BehaviorSubject<string[]> | undefined =
    new BehaviorSubject<string[]>([]);

  loading$: Observable<boolean>;
  constructor(
    private staffSvc:StaffService,
    private staffDialogSvc:StaffDialogService,
    private uiSvc:UiService,
    private changeDetector : ChangeDetectorRef,
  ) {
    this.loading$ = this.isLoadingResults$;
    this.dataSource = new MatTableDataSource();
  }


  ngOnInit(): void {
    this.staffSvc.listAppPermissions().subscribe(this.appPermissions$);
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.registerTableComponent();
    this.staffs$ = merge(
        this.refresh$,
        this.paginator.page,
    ).pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults$.next(true);
          return this.staffSvc.listStaff();
        }),
        map((res) => {
          this.isLoadingResults$.next(false);
          this.changeDetector.detectChanges();
          this.dataSource = new MatTableDataSource(res);
          this.registerTableComponent();
          this.changeDetector.detectChanges();
          return res;
        }),
        tap(() => {
          this.refresh.emit();
        }),
    );

    this.staffs$.subscribe();
  }

  rowSelected(staff:Staff) {
    this.selected.emit(staff);
  }


  private registerTableComponent() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  invokeRefresh($event: boolean) {
    this.refresh$.next(null);
  }
}
