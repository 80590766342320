import {Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GenericGoldService, QueryResult} from './gold-generic-service';

export interface GoldShopItem{
  name: string,
  quantity: number,
  price: number,
}

export interface GoldshopPayment {
  id: string,
  userId: string,
  orderId: string,
  refTradeNo?: string,
  paymentCvsCode?: string,
  paymentCvsCodeUrl?: string,
  paidAt?: string | null,
  createdAt: string,
  createdBy: string,
  updatedAt: string,
  updatedBy: string,
  amount: number,
  items: GoldShopItem[],
  invoiceMethod: string,
  invoiceNumber: string,
  invoicedAt?: string,
  paymentMethod: string,
  paymentMethodName: string,
  description?: string,
  version: string
}

export interface GoldshopPaymentCreateResponse {
  message: string,
  id: string,
}

export interface GoldShopPaymentQueryParams {
  pageNumber?: number | null | undefined;
  pageSize?: number | null | undefined;
  orderId?: string | null | undefined;
  userId?: string | null | undefined;
  refTradeNo?: string | null | undefined;
  invoiceMethod?: string | null | undefined;
  paymentMethod?: string | null | undefined;
  hasPaid?: 'Y' | 'N' | '' | null | undefined;
  isExpired?: 'Y' | 'N' | '' | null | undefined;
}

export interface GoldShopPaymentGetResponse {
  message: string;
  body: GoldshopPayment;
}

@Injectable({
  providedIn: 'root',
})
export class GoldshopPaymentService extends
  GenericGoldService<GoldshopPayment> {
  constructor() {
    super('payment', 'payments');
  }

  override query(params: GoldShopPaymentQueryParams):
  Observable<QueryResult<GoldshopPayment>> {
    return this.http.post<QueryResult<GoldshopPayment>&{message:string}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`,
        params,
    ).pipe(map((v)=>{
      if (v.body) {
        v.body = v.body.map((payment) => {
          payment.amount /= 100000;

          payment.items = payment.items.map((item) => ({
            ...item,
            price: item.price / 100000,
          }));

          return payment;
        });
      }

      return v;
    }));
  }

  markAsPaid(id: string): Observable<{message:string}> {
    return this.http.put<{message:string}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/${id}/paid`,
        null,
    );
  }

  override get(id: string): Observable<GoldshopPayment> {
    return this.http.get<GoldShopPaymentGetResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/${id}`,
    ).pipe(map((v)=> {
      const payment: GoldshopPayment = v.body;
      payment.amount /= 100000;

      payment.items = payment.items.map((item) => ({
        ...item,
        price: item.price / 100000,
      }));

      return payment;
    }));
  }

  generateEInvoice(id: string, params:{
    invoiceMethod: string
  }): Observable<{message:string}> {
    return this.http.post<{message:string}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.name}/${id}/einvoice`,
        params,
    );
  }
}
