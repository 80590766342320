import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';

export interface GoldCollection{
  variationId: string
  productId: string
  orderId: string
  paymentStatus: string
  productInfo?: ProductInfo
  orderInfo?: OrderInfo
}
export interface ProductInfo{
  id: string
  shopId: string
  sku: string
  name: string
  images: string[]
  videos: string[]
  categoryId: string
  description: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  isDeleted: string
  version: string
}

export interface OrderInfo{
  id: string
  createdAt: string
}
export interface GoldCollections{
  body: GoldCollection[]
  message: string;
  totalData: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoldCollectionService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) {}

  queryCollection(id: string):Observable<GoldCollections> {
    return this.http.post<GoldCollections>(
        this.goldshopApiUrl + '/gold/api/v0/staff/my-collections/' + id,
        {},
    );
  }
}
