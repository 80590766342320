<h1 mat-dialog-title>Upload Gambar</h1>
<div *ngIf="!isUploading" mat-dialog-content class="container" (drop)="drop($event)" (dragover)="allowDrop($event)" (paste)="paste($event)">
  <div class="drop-box" *ngIf="!file">
    <p>Drag an image here, paste it, or select it from the file. (max 5MB)</p>
    <input type="file" (change)="handleFileInput($event.target)" accept="image/*">
  </div>
  <img *ngIf="imagePreview" [src]="imagePreview" alt="Image preview" class="image-preview">
</div>

<div *ngIf="isUploading" mat-dialog-content style="display: flex; flex-direction: column; align-items: center;">
  <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
</div>
<ng-container *ngIf="errorMessage">
  <div class="error-box">
    {{errorMessage}}
  </div>
</ng-container>

<div mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-flat-button color="primary" (click)="confirmSubmit()" [disabled]="!(file && !isUploading)" >Confirm Upload</button>
</div>