import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, map} from 'rxjs';
import {GenericGoldService, GoldShopsQueryParams, QueryResult} from './gold-generic-service';


export interface GoldShippingChannel{
  id: string
  name: string
  cost: number
  isDeleted: string,
  version: string,
  updatedAt: string,
  updatedBy: string,
  createdAt: string,
  createdBy: string,
}

@Injectable({
  providedIn: 'root',
})
export class GoldShippingChannelService extends
  GenericGoldService<GoldShippingChannel> {
  constructor() {
    super('shipping-method', 'shipping-methods');
  }

  override get(id: string): Observable<GoldShippingChannel> {
    return super.get(id).pipe(
        map((response)=> {
          return this.transformCost(response);
        }),
    );
  }

  override query(params: GoldShopsQueryParams):
  Observable<QueryResult<GoldShippingChannel>> {
    return super.query(params).pipe(
        map((resp)=> {
          const response: QueryResult<GoldShippingChannel> = {
            body: resp.body?.map((c) => this.transformCost(c)),
            totalData: resp.totalData,
          };
          return response;
        }),
    );
  }

  private transformCost(product: GoldShippingChannel) {
    const cs = _.cloneDeep(product);
    const cost = (cs.cost ?? 0) as number / 100000;
    cs.cost = cost;
    return cs;
  }
}
