<section [formGroup]="permissionForm">
  <h1 mat-dialog-title>Set Permission for {{data.staff.name || data.staff.email}}</h1>
  <div mat-dialog-content class="content">
    <h4>Roles:</h4>
    <mat-slide-toggle formControlName="manager">Manager</mat-slide-toggle>
    <mat-slide-toggle formControlName="staff">Staff</mat-slide-toggle>
    <mat-form-field appearance="outline">
      <mat-label>Kode Agen Indopos</mat-label>
      <input matInput placeholder="123" formControlName="idpAgentCode">
    </mat-form-field>
    <h4>Permissions:</h4>
    <ng-container formGroupName="checkboxes">
      <p *ngFor="let item of permissionForm.controls.checkboxes.controls| keyvalue">
        <mat-checkbox [formControlName]="item.key" [value]="item.value" >{{item.key}}</mat-checkbox>
      </p>
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="submit()">Save</button>
  </div>
</section>
