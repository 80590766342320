// import statements
import {CommonModule} from '@angular/common';
import {Component, Inject} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {Observable} from 'rxjs';


const MAX_SIZE = 5242880;

export interface ImageDialogUploadFunction{
  upload: (file: File)=> Observable<string>
}


@Component({
  selector: 'indosuara-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
})
export class ImageDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<
    ImageDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: ImageDialogUploadFunction,
  ) {
    dialogRef.disableClose = true;
  }

  file?: File;
  imagePreview?: string | ArrayBuffer | null;

  isUploading = false;

  errorMessage = '';


  handleFileInput(event?: EventTarget | null ) {
    const inputEvent = event as HTMLInputElement;
    const inputFile = inputEvent?.files?.item(0);
    if (!inputFile) return;
    if (inputFile?.size > MAX_SIZE) {
      alert('File size is larger than ' + MAX_SIZE / 1024 / 1024 + 'MB');
      return;
    }
    this.file = inputEvent?.files?.item(0) ?? undefined;

    const reader = new FileReader();
    reader.onload = (e) => this.imagePreview = reader.result;
    reader.readAsDataURL(inputFile);
  }

  drop(event: any) {
    event.preventDefault();
    this.handleFileInput(event.dataTransfer);
  }

  allowDrop(event: any) {
    event.preventDefault();
  }

  paste(event: any) {
    this.handleFileInput(event.clipboardData);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  confirmSubmit() {
    if (!this.file) {
      alert('Please select an image.');
      return;
    }
    this.isUploading = true;
    this.data.upload(this.file).subscribe({
      error: (err)=>{
        this.isUploading = false;
        this.errorMessage = err;
      },
      next: (image)=>{
        this.isUploading = false;
        return this.dialogRef.close(image);
      }},
    );
  }
}
