import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StaffForm} from './staff.firebase.service';
import {Staff} from './staff.interface';

export interface Permission {
  name: string;
  permitted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export abstract class StaffService {
  abstract createStaff(data: StaffForm): Observable<any>;
  abstract listStaff(): Observable<Staff[]>;
  abstract disableStaff(uid: string): Observable<any>;
  abstract listAppPermissions(): Observable<string[]>;
  abstract setStaffPermissions(
    uid:string,
    permissions:string[],
    ): Observable<any>;
  abstract setStaffClaims(
    uid:string,
    claims:{
      manager: boolean,
      staff: boolean,
      idpAgentCode: string;
      permissions:string[],
    },
    ): Observable<any>;

  abstract generatePermissionState(
    staff: Staff, appPermissions: string[]): Permission[];
}

