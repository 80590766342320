import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '@indosuara/auth';
import {map, take} from 'rxjs';

export const redirectToIndoposHomePage: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.authState().pipe(
      map((user) => {
        return user?.permissions.
            map((perm)=> perm.split(':')[0]).
            includes('indopos') ?? false;
      }),
      map((isIndoposStaff)=>{
        if (isIndoposStaff) {
          router.navigate(['/indopos']);
        }
        return !isIndoposStaff;
      }),
      take(1),
  );
};

export const redirectToIndoposHomePageChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot,
) => redirectToIndoposHomePage(route, state);
