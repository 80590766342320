import {FlatTreeControl} from '@angular/cdk/tree';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';

import {Router} from '@angular/router';
import {AuthService} from '@indosuara/auth';
import {SubSink} from 'subsink';
import {NavigationService} from './navigation-service.service';
import {SideNavNode} from './sidenav-data';

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  url: string | undefined;
  icon?: string | undefined;
}

@Component({
  selector: 'indosuara-navigation-menu',
  styleUrls: ['./navigation-menu.component.css'],
  templateUrl: './navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnDestroy, OnInit {
  constructor(
    private authSvc: AuthService,
    private navService: NavigationService,
    public router: Router,
  ) {
    this.user$ = this.authSvc.authState();
  }

  user$;

  subs = new SubSink();
  treeControl =new FlatTreeControl<FlatNode>(
      (node) => node.level,
      (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
      this._transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children,

  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


  private _transformer(node: SideNavNode, level: number) : FlatNode {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      url: node.route,
      icon: node.icon,
    };
  }


  ngOnInit(): void {
    this.subs.sink = this.navService.navigations().subscribe({
      next: (navs) => this.dataSource.data = navs,
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;


  isActive(url: string): boolean {
    return this.router.url.startsWith(url);
  }

  isInternalLink(url: string): boolean {
    return /^\/[^/]/.test(url);
    // This regex checks if the URL starts with a '/' but not '//'
  }
}
export {NavigationService};

