import {Injectable} from '@angular/core';
import {GenericGoldService} from './gold-generic-service';


export interface Manufacturer{
  id: string
  name:string
}

@Injectable({
  providedIn: 'root',
})
export class ItemManufactureService extends GenericGoldService<Manufacturer> {
  constructor() {
    super('manufacturer', 'manufacturers');
  }
}
