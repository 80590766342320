import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';


import {SetPermissionDialogComponent} from './dialog-services/set-permission-dialog/set-permission-dialog.component';
import {FieldErrorModule} from './field-error/field-error.module';
import {ManagerHomeComponent} from './manager-home/manager-home.component';
import {ManagerMaterialModule} from './manager-material.module';
import {ManagerRoutingModule} from './manager-routing.module';
import {ManagerComponent} from './manager.component';
import {StaffActionMenuItemComponent} from './staff-action-menu-item/staff-action-menu-item.component';
import {StaffFormComponent} from './staff-form/staff-form.component';
import {StaffTableComponent} from './staff-table/staff-table.component';
import {StaffViewComponent} from './staff-view/staff-view.component';
import {UserManagementComponent} from './user-management/user-management.component';

@NgModule({
  declarations: [
    ManagerComponent,
    ManagerHomeComponent,
    SetPermissionDialogComponent,
    StaffActionMenuItemComponent,
    StaffFormComponent,
    StaffTableComponent,
    StaffViewComponent,
    UserManagementComponent,
  ],
  imports: [
    CommonModule,
    FieldErrorModule,
    ManagerMaterialModule,
    ManagerRoutingModule,
    ReactiveFormsModule,
  ],
})
export class ManagerModule {}
