import {InjectionToken} from '@angular/core';

export const INDOPOS_BASE_URL = new InjectionToken<string>(
    'Base Url for Indopos Api', {
      providedIn: 'root',
      factory: () => 'localhost',
    },
);


export const INDOPOS_DRIVER_FEATURE_ENABLED =
new InjectionToken<boolean>('indoposDriverFeatureEnabled', {
  providedIn: 'root',
  factory: () => false,
});


export const INDOPOS_INVENTORY_FEATURE_ENABLED =
new InjectionToken<boolean>('indoposInventoryFeatureEnabled', {
  providedIn: 'root',
  factory: () => false,
});
