import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';


export interface APIResponse {
  status?: string;
  code?: number;
  message?: string;
  error?: string;
}


export interface BannerQueryParams {
  pageSize?: number
  pageNumber?: number
  isActive?: 'Y'| 'N' | '' |null;
}

export interface IndoposBanners{
  body: IndoposBanner[]
  totalData: number
}

export type IndoposBanner =
  IndoposBannerInfo & IndoposBannerEditable

export interface IndoposBannerInfo{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  version: string
}

export interface IndoposBannerEditable{
  imageUrl: string
  url: string
  isActive: 'Y'| 'N' | '';
}


@Injectable()
export abstract class ContentBannerService {
  abstract queryBanners(params: BannerQueryParams): Observable<IndoposBanners>;
  abstract createBanner(
    params: Partial<IndoposBannerEditable>
    ): Observable<APIResponse>;
  abstract updateBanner(id: string, params: Partial<IndoposBannerEditable>,
    ): Observable<APIResponse>;
  abstract getBanner(id: string): Observable<IndoposBanner>;
  abstract uploadPublicImage(file: File): Observable<string>;
}
