import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, tap} from 'rxjs/operators';
import {EmailValidation, PasswordValidation} from '../common/validations';

import {AuthService} from '@indosuara/auth';
import {UiService} from '@indosuara/common-dialog-ui';
import {combineLatest} from 'rxjs';
import {SubSink} from 'subsink';

@Component({
  selector: 'indosuara-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
})
/**
 * Login Component
 */
export class LoginComponent implements OnInit {
  private subs = new SubSink();
  loginForm!: UntypedFormGroup;
  loginError = '';
  redirectUrl!: string;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private uiService: UiService,
  ) {
    this.subs.sink = route.queryParamMap.subscribe({
      next: (value)=>{
        this.redirectUrl = value.get('redirectUrl') ?? '/';
        console.log(this.redirectUrl);
      },
    },
    );
  }


  ngOnInit() {
    this.authService.logout();
    this.buildLoginForm();

    this.subs.sink = combineLatest([
      this.authService.authState(),
    ])
        .pipe(
            filter(
                (
                    [authStatus],
                ) => !!authStatus?.uid && !!authStatus.staff),
            tap(([authStatus]) => {
              console.log(this.redirectUrl);
              console.log(authStatus);
              if (this.redirectUrl != '/') {
                this.router.navigateByUrl(this.redirectUrl);
              } else if (authStatus?.permissions.includes('indopos:staff')) {
                this.router.navigate(['/indopos/sales-drafts']);
              } else {
                this.router.navigate(['/']);
              }
            }),
        ).subscribe();
  }

  buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', EmailValidation],
      password: ['', PasswordValidation],
    });
  }

  async login(submittedForm: UntypedFormGroup) {
    this.authService
        .login(submittedForm.value.email, submittedForm.value.password)
        .subscribe({error: (err) => this.uiService.showToast(`Error ${err}`)});
  }

  async loginWithGoogle() {
    this.authService
        .loginWithGoogle()
        .subscribe({error: (err) => this.uiService.showToast(`Error ${err}`)});
  }
}
