import {RouterModule, Routes} from '@angular/router';
import {canActivateLoggedInChild, canActivateSuperAdmin} from '@indosuara/auth';

import {NgModule} from '@angular/core';
import {redirectToIndoposHomePageChild} from '@indosuara/indopos-staff-config';
import {shopsRoutes} from '@indosuara/shops';
import {environment} from '../environments/environment';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

let devRoutes: Routes = [];

if (!environment.production) {
  devRoutes = [];
}

const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full',
  },
  {path: 'login', component: LoginComponent},
  {path: 'login/:redirectUrl', component: LoginComponent},
  {
    path: 'home', component: HomeComponent,
    canActivate: [canActivateLoggedInChild, redirectToIndoposHomePageChild],
  },
  {
    path: 'manager',
    loadChildren: () =>
      import('@indosuara/superadmin').then((m) => m.ManagerModule),
    canActivate: [canActivateLoggedInChild, canActivateSuperAdmin],
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'inventory',
    loadChildren: () =>
      import('./inventory/inventory.module').then((m) => m.InventoryModule),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./sales/sales.module').then((m) => m.SalesModule),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'customer-service',
    loadChildren: () =>
      import('./customer-service/customer-service.module').then(
          (m) => m.CustomerServiceModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'indopos',
    loadChildren: () =>
      import('@indosuara/indopos-staff').then(
          (m) => m.IndoposModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'emasku',
    loadChildren: () =>
      import('@indosuara/goldshop').then(
          (m) => m.GoldshopModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'flight',
    loadChildren: () =>
      import('@indosuara/flight').then(
          (m) => m.FlightModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'loan',
    loadChildren: () => import('@indosuara/indo-worker-loan-staff-ui').
        then((m) => m.LoanModule),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'physical-store',
    loadChildren: () =>
      import('@indosuara/physical-store-ui').then(
          (m) => m.PhysicalStoreModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'exchange-rate',
    loadChildren: () =>
      import('./exchange-rate/exchange-rate.module').then(
          (m) => m.ExchangeRateModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'hs',
    loadChildren: () =>
      import('@indosuara/homeshop-ui').then(
          (m) => m.HomeshopUiModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'remit',
    loadChildren: () =>
      import('@indosuara/remit').then(
          (m) => m.RemitModule,
      ),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'developer/basic',
    loadChildren: () =>
      import('./developer-page/developer-page.module').
          then((m) => m.DeveloperPageModule),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'change-password',
    loadComponent: () =>
      import('@indosuara/auth-ui').then((m) => m.ChangePasswordComponent),
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: 'shops',
    children: [
      ...shopsRoutes,
    ],
    canActivate: [canActivateLoggedInChild],
  },
  {
    path: '**', component: PageNotFoundComponent,
    canActivate: [canActivateLoggedInChild],
  },
];

@NgModule({
  imports: [RouterModule.forRoot([...devRoutes, ...routes])],
  exports: [RouterModule],
})

/**
 * Main app routing module
 */
export class AppRoutingModule {}
