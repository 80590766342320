import {HttpErrorResponse} from '@angular/common/http';
import {FirebaseError} from 'firebase/app';
import {throwError} from 'rxjs';


export class AppError extends Error {
  readonly code: string;
  override readonly name = 'AppError';
  constructor(code: string, message: string) {
    super();
    this.code = code;
    this.message = message;
  }
}

export function checkAppError(res: any) {
  if (!res?.code) return res;
  if (res?.code != 200 ) {
    throw new AppError(res.code, res.message);
  }
  return res;
}

export function transformError(
    error: HttpErrorResponse | string | FirebaseError | AppError,
) {
  let errorMessage = 'An unknown error has occurred';
  if (typeof error === 'string') {
    errorMessage = error;
  } else if (
    error instanceof HttpErrorResponse &&
    error.error instanceof ErrorEvent
  ) {
    errorMessage = `Error Event! ${error.error.message}`;
  } else if (error instanceof HttpErrorResponse && error.status) {
    errorMessage = `Request failed with ${error.status} ${error.statusText}`;
  } else if (error instanceof FirebaseError) {
    errorMessage = `${error.message}`;
  } else if (error instanceof AppError) {
    errorMessage = `${error.code} : ${error.message}`;
  } else if (error instanceof Error) {
    errorMessage = `error ${error.message}`;
  }
  return throwError(() => errorMessage);
}

