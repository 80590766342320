import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericGoldService, GoldShopsQueryParams, QueryResult} from './gold-generic-service';

export interface GoldShopInvoiceMethod {
    id: string,
    createdAt: string,
    createdBy: string,
    updatedAt: string,
    updatedBy: string,
    name: string,

}


@Injectable({
  providedIn: 'root',
})
export class GoldshopInvoiceService extends
  GenericGoldService<GoldShopInvoiceMethod> {
  constructor() {
    super('invoice-method', 'invoice-methods');
  }

  override query(params: GoldShopsQueryParams):
  Observable<QueryResult<GoldShopInvoiceMethod>> {
    return this.http.post<QueryResult<GoldShopInvoiceMethod>>(
        this.goldshopApiUrl + `/gold/api/v0/staff/${this.namePlural}`,
        params,
    );
  }
}
