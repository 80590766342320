import {Component, Inject} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
} from '@angular/forms';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
} from '@angular/material/dialog';
import {UiService} from '@indosuara/common-dialog-ui';
import {filter, map, take, tap} from 'rxjs';

import {Staff} from '../../staff/staff.interface';
import {Permission, StaffService} from '../../staff/staff.service';

@Component({
  selector: 'indosuara-set-permission-dialog',
  templateUrl: './set-permission-dialog.component.html',
  styleUrls: ['./set-permission-dialog.component.css'],
})
/**
 *
 */
export class SetPermissionDialogComponent {
  permissionForm = this._formBuilder.group({
    manager: new FormControl<boolean>(false, {nonNullable: true}),
    staff: new FormControl<boolean>(false, {nonNullable: true}),
    idpAgentCode: new FormControl<string>('', {nonNullable: true}),
    checkboxes: new FormGroup({ }),
  });
  constructor(
    private _formBuilder: FormBuilder,
    private staffSvc: StaffService,
    private uiSvc: UiService,
    public dialogRef: MatDialogRef<SetPermissionDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: {
      staff: Staff,
      permissions:Permission[]
    },
  ) {
    this.buildPermissionForm();
  }

  private buildPermissionForm() {
    this.permissionForm.controls.manager.patchValue(
        this.data.staff.manager ?? false);
    this.permissionForm.controls.staff.patchValue(
        this.data.staff.staff ?? false);
    this.permissionForm.controls.idpAgentCode.patchValue(
        this.data.staff.idpAgentCode ?? '');
    this.staffSvc.listAppPermissions().pipe(
        take(1),
        map((v)=> this.staffSvc.generatePermissionState(this.data.staff, v)),
        filter((v)=> !!v),
        tap((v)=> {
          (v ?? []).forEach((permissions) => {
            this.permissionForm
                .controls['checkboxes']
                .addControl(
                    permissions?.name ?? '',
                    new FormControl(permissions.permitted),
                );
          });
        }),
    ).subscribe();
  }

  submit(): void {
    const result = this.permissionForm.controls.checkboxes.value;
    if (!result) return;
    const perm : string[] = [];
    for (const [key, value] of Object.entries(result)) {
      if (value) {
        perm.push(key);
      }
    }
    this.uiSvc.showToast('Saving...');
    this.staffSvc.setStaffClaims(
        this.data.staff.uid,
        {
          manager: this.permissionForm.controls.manager.value,
          staff: this.permissionForm.controls.staff.value,
          idpAgentCode: this.permissionForm.controls.idpAgentCode.value,
          permissions: perm,
        },
    ).subscribe({
      next: (val) => {
        this.uiSvc.showToast(val?.message || 'Done Save');
      },
      error: (err) => {
        this.uiSvc.showToast(err);
      },
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }
}
