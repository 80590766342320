import {Observable, catchError, map, tap} from 'rxjs';
import {
  checkAppError,
  transformError,
} from '../../common/common';

import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {CustomerProfile} from './customer-profile.model';
import {CustomerProfileService} from './customer-profile.service';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
/**
 *
 */
export class FirebaseCustomerProfileService extends CustomerProfileService {
  constructor(
    private afs: AngularFirestore,
    private afn: AngularFireFunctions,
  ) {
    super();
  }
  queryByEmail(email: string): Observable<CustomerProfile[]> {
    return this.afs.collection<CustomerProfile>('users',
        (ref) => ref.where('email', '==', email)).valueChanges({idField: 'id'});
  }
  queryByPhone(phone: string): Observable<CustomerProfile[]> {
    return this.afs.collection<CustomerProfile>('users',
        (ref) => ref.where('phoneNumber', '==', phone))
        .valueChanges({idField: 'id'});
  }
  queryByName(name: string): Observable<CustomerProfile[]> {
    return this.afs.collection<CustomerProfile>('users',
        (ref) => ref.where('name', '>=', name)
            .where('name', '<=', name + '\uf8ff'))
        .valueChanges({idField: 'id'});
  }
  get(uid: string): Observable<CustomerProfile> {
    throw new Error('Method not implemented.');
  }

  softBanUser(uid: string): Observable<any> {
    return this.callFirebaseFunction('userManagement-applySoftBan', {
      uid: uid,
    });
  }
  unSoftBanUser(uid: string): Observable<any> {
    return this.callFirebaseFunction('userManagement-upliftSoftBan', {
      uid: uid,
    });
  }

  private callFirebaseFunction(endpoint: string, data: any = {}) {
    const callable = this.afn.httpsCallable(endpoint);
    const response$ = callable(data).pipe(
        tap((res: any)=>{
          console.log(res);
        }),
        map(checkAppError),
        catchError(transformError),

    );
    return response$;
  }
}
