import {Injectable} from '@angular/core';
import {GenericGoldService} from './gold-generic-service';


export interface GoldInvoiceChannel{
  id: string
  name: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}


@Injectable({
  providedIn: 'root',
})
export class GoldInvoiceChannelService extends
  GenericGoldService<GoldInvoiceChannel> {
  constructor() {
    super('invoice-method', 'invoice-methods');
  }
}

