import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {UiService} from '@indosuara/common-dialog-ui';
import {BehaviorSubject} from 'rxjs';

import {StaffDialogService} from '../dialog-services/staff-dialog.service';
import {Staff} from '../staff/staff.interface';
import {StaffService} from '../staff/staff.service';

@Component({
  selector: 'indosuara-staff-action-menu-item [staff]',
  templateUrl: './staff-action-menu-item.component.html',
  styleUrls: ['./staff-action-menu-item.component.css'],
})
/**
 *
 */
export class StaffActionMenuItemComponent implements OnInit {
  @Input() staff!:Staff;
  appPermissions$: BehaviorSubject<string[]> | undefined =
    new BehaviorSubject<string[]>([]);

  @Output() refresh = new EventEmitter<boolean>();

  constructor(
    private staffSvc:StaffService,
    private staffDialogSvc:StaffDialogService,
    private uiSvc:UiService,
  ) {
  }
  ngOnInit(): void {
    this.staffSvc.listAppPermissions().subscribe(this.appPermissions$);
  }

  disabledEditPerm() {
    if (this.staffIsNotManager()) {
      return this.staffIsNotActive();
    }
    return false;
  }


  disabledDisableStaff() {
    if (this.staffIsNotManager()) {
      return this.staffIsNotActive();
    }
    return false;
  }

  private staffIsNotManager() {
    return !this.staff.manager;
  }

  private staffIsNotActive() {
    return this.staff.disabled;
  }


  disabledReason(): string {
    if (!this.staff.staff) {
      return 'This account is disabled.';
    }

    return 'Disabled.';
  }

  editPermission(staff:Staff) {
    if (!this.appPermissions$) {
      this.uiSvc.showToast('Failed to retreive permissions');
      return;
    }
    const permissions = this.staffSvc.generatePermissionState(
        staff,
        this.appPermissions$.value,
    );
    this.staffDialogSvc.showStaffPermissionForm(
        staff,
        permissions,
    ).subscribe({
      complete: () =>{
        this.refresh.emit(true);
      },
    });
  }

  disableStaff(staff:Staff) {
    this.uiSvc.showDialog('Are you Sure?',
        `This action will disable ${staff.name || staff.email}'s account.`,
        `Disable ${staff.name || staff.email}`,
        'Cancel')
        .subscribe({
          next: (result) => {
            if (!result) return;
            this.callDisableStaffService(staff);
          },
        });
  }


  private callDisableStaffService(staff: Staff) {
    this.uiSvc.showToast(`Disabling ${staff.name || staff.email}...`);
    this.staffSvc.disableStaff(staff.uid).subscribe({
      next: (response) => {
        this.uiSvc.showToast(response.message);
      },
      error: (err) => {
        this.uiSvc.showToast(err.message);
      },
    });
  }
}
