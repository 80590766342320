import {
  BehaviorSubject,
} from 'rxjs';
import {Component} from '@angular/core';
import {Staff} from '../staff/staff.interface';

@Component({
  selector: 'indosuara-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
})
/**
 * User Management Component
 */
export class UserManagementComponent {
  selectedStaff$: BehaviorSubject<Staff | undefined> =
    new BehaviorSubject<Staff | undefined>(undefined);


  staffSelected(event:Staff): void {
    this.selectedStaff$.next(event);
  }

  deselect(): void {
    this.selectedStaff$.next(undefined);
  }
}
