import {CommonModule} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
  ],
  template: `
    <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content>
      <p>{{ data.content }}</p>
      <mat-form-field appearance="outline" style="width:100%;">
        <textarea matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="5"
            type="text"  [formControl]="reason">
        </textarea>
        <mat-error>required</mat-error>
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions>
      <span class="flex-spacer"></span>
      <button mat-button mat-dialog-close *ngIf="data.cancelText">
        {{ data.cancelText }}
      </button>
      <button
      mat-button
      mat-button-raised
      color="primary"
      (click)="onSubmit()"
      cdkFocusInitial
      >
        {{ data.okText }}
      </button>
    </mat-dialog-actions>
  `,
})

export class AskTextDialogComponent implements OnInit {
  reason = new FormControl<string>('', {
    nonNullable: true,
    validators: [
      Validators.required,
    ],
  });
  constructor(
    public dialogRef: MatDialogRef<AskTextDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  ngOnInit(): void {
    this.reason.setValue(this.data.initText ?? '', {emitEvent: false});
  }

  onSubmit() {
    if (this.reason.invalid) {
      this.reason.markAllAsTouched();
      return;
    }
    this.dialogRef.close(this.reason.value);
  }
}
