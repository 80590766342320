export interface BasicInfo {
  arc_back_url: string;
  arc_front_url: string;
  arc_no: string;
  debtor_id: string;
  full_name: string;
  ktp_url: string;
  passport_url: string;
  phone_id: string;
  phone_tw: string;
  reply_url: string;
  salary_slip_url: string;
}

export interface InterviewInfo {
  interviewed_at: string;
  interviewed_by: string;
  is_interview_accepted: boolean;
  reason: string;
  ss_url: string;
}

export interface Todo {
  interview: boolean;
  risk_check: boolean;
  validation: boolean;
}

export interface ValidationInfo {
  is_valid: boolean;
  reason: string;
  validated_at: string;
  validated_by: string;
}

export interface VerificationInfo {
  approved_at: string;
  approved_by: string;
  is_approved: boolean;
  reason: string;
}

export interface Debtor {
  basic_info: BasicInfo;
  interview_info: InterviewInfo;
  todo: Todo;
  validation_info: ValidationInfo;
  verification_info: VerificationInfo;
}

export interface ValidationReport {
  debtor_id: string;
  is_valid: boolean;
  reason: string;
}

export interface RiskCheckReport {
  debtor_id: string;
  is_approved: boolean;
  reason: string;
}

export interface InterviewReport {
  debtor_id: string;
  is_interview_accepted: boolean;
  reason: string;
  ss_url: string;
}

export interface ShoppingInfo {
  address: string;
  createTime: string;
  handphone: string;
  memberId: string;
  name: string;
  packageNo: string;
  status: string;
  telp: string;
  transactionNo: string;
}

export interface MemberInfo {
  address?: string;
  arcExpire?: string;
  birthday?: string;
  createTime?: string;
  memberId?: string;
  memberType?: string;
  name?: string;
  telp1?: string;
  telp2?: string;
}

export interface IsRemitInfo {
  agentRowId?: string;
  alamat?: string;
  arcExpire?: string;
  arcNo?: string;
  bank?: string;
  biayaKirim?: string;
  cabang?: string;
  comment?: string;
  createTime?: string;
  custId?: string;
  diskon?: string;
  jumlahRemit?: string;
  kurs?: string;
  namaPenerima?: string;
  namaPengirim?: string;
  nationality?: string;
  noRekening?: string;
  reporter?: string;
  rupiah?: string;
  shop?: string;
  status?: string;
  statusId?: string;
  telp1?: string;
  telp2?: string;
  telpPenerima?: string;
  transactionId?: string;
  transfererProblem?: string;
  vip?: string;
}

