import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {APIResponse, BannerQueryParams, ContentBannerService, IndoposBanner, IndoposBannerEditable, IndoposBanners} from '@indosuara/content-ui';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';

@Injectable({
  providedIn: 'root',
})
export class GoldshopContentService implements ContentBannerService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) { }
  queryBanners(params: BannerQueryParams): Observable<IndoposBanners> {
    return this.http.post<IndoposBanners>(
        this.goldshopApiUrl + '/gold/api/v0/staff/contents/banners',
        params,
    );
  }
  createBanner(params: Partial<IndoposBannerEditable>):
  Observable<APIResponse> {
    return this.http.post<APIResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/contents/banners/create',
        params,
    );
  }
  updateBanner(id: string, params: Partial<IndoposBannerEditable>):
  Observable<APIResponse> {
    return this.http.put<APIResponse>(
        this.goldshopApiUrl + `/gold/api/v0/staff/contents/banners/${id}`,
        params,
    );
  }
  getBanner(id: string): Observable<IndoposBanner> {
    return this.http.get<{body: IndoposBanner}>(
        this.goldshopApiUrl + `/gold/api/v0/staff/contents/banners/${id}`,
    ).pipe(map((v)=> v.body));
  }
  uploadPublicImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<APIResponse & {url:string}>(
        `${this.goldshopApiUrl}/gold/api/v0/staff/contents/images`,
        formData,
    ).pipe(map((v)=>v.url));
  }
}
