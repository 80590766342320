<mat-tree
  [dataSource]="dataSource"
  [treeControl]="treeControl"
>
  <mat-tree-node *matTreeNodeDef="let node"  matTreeNodePadding [matTreeNodePaddingIndent]="node.level * 10">
    <a
      *ngIf="isInternalLink(node.url)"
      [routerLink]="node.url"
      routerLinkActive="active-link"
      rel="{{isInternalLink(node.url) ? null : 'noopener noreferrer'}}"
      class="parent-node"
    >

    
    <mat-icon *ngIf="node.icon">
      {{node.icon}}
    </mat-icon>
    <div>
      {{ node.name }}
    </div>
    </a>

    <a
      *ngIf="!isInternalLink(node.url)"
      [href]="node.url"
      routerLinkActive="active-link"
      target="_blank"
      rel="noopener noreferrer"
      class="parent-node"
      matTooltip="Open in new tab"
    >
    <mat-icon *ngIf="node.icon">
      {{node.icon}}
    </mat-icon>
    <div>
      {{ node.name }}
    </div>
    </a>
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding [matTreeNodePaddingIndent]="node.level * 10">
      <div matTreeNodeToggle 
        class="parent-node justify-content-space-between"
        [class.active-link]="isActive(node.url)"
      >
        <div style="display: flex; flex-direction: row; gap: 0.5rem; align-items: center;">
          <mat-icon *ngIf="node.icon">
            {{node.icon}}
          </mat-icon>
          <div>
            {{ node.name }}
          </div>
        </div>
        <mat-icon>
          {{ treeControl.isExpanded(node) ? 'expand_less' : 'expand_more' }}
        </mat-icon>
      </div>
  </mat-tree-node>
</mat-tree>
<mat-divider></mat-divider>
<ng-container *ngIf="user$ | async as user">

<div style="display: flex;">
  <button class="profile-button">
    <mat-icon aria-hidden="false" fontIcon="account_circle"> </mat-icon>
    <div style="display: flex; flex-direction: column; align-items: flex-start;">
      <div style="font-weight: bold;">
        {{user.name || '---'}}
      </div>
      <div style="font-size: small; opacity: 0.7;">
        {{user.email}}
      </div>
    </div>
  </button>
</div>
</ng-container>

