import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FLIGHT_BASE_URL} from './config';

export interface MarkupPriceRequest {
  markupAmount:number;
}

export interface MarkupPrices{
  totalData: number
  body: MarkupPrice[]
}

export type MarkupPrice=MarkupPriceRequest&{
  id: string
  isDeleted: 'Y' | 'N'
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface MarkupPriceQueryParams{
  pageNumber?: number |null
  pageSize?: number |null
  isDeleted?: 'Y' | 'N' | '' | null
}

export interface MarkupPriceResponse{
  id?:string
  message?: string;
  body?:MarkupPrice;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FlightMarkupPriceService {
  constructor(
    private http: HttpClient,
    @Inject(FLIGHT_BASE_URL) private flightApiUrl: string,
  ) { }

  query(params: MarkupPriceQueryParams):Observable<MarkupPrices> {
    return this.http.post<MarkupPrices>(
        this.flightApiUrl + '/flight/api/v0/staff/markup-prices',
        params,
    );
  }

  set(params: MarkupPriceRequest):Observable<MarkupPriceResponse> {
    return this.http.post<MarkupPriceResponse>(
        this.flightApiUrl+'/flight/api/v0/staff/markup-price',
        params,
    );
  }

  getLatest():Observable<MarkupPriceResponse> {
    return this.http.get<MarkupPriceResponse>(
        this.flightApiUrl+'/flight/api/v0/staff/markup-price',

    );
  }
}
