import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable, map} from 'rxjs';
import {GOLDSHOP_BASE_URL} from './config';
import {GoldShopApiResponse} from './kyc/user-verification.service';

export interface MemberQueryParams {
  pageNumber?: number
  pageSize?: number
  sortBy?: string
  sortDirection?: string | null
  name?: string | null
  passportNumber?: string | null
}

export interface GoldShopMembers{
  body: GoldShopMember[]
  totalData: number
}

export type GoldShopMember =
  GoldshopMemberBasicInfo & GoldShopMemberUpdatableInfo

export interface GoldshopMemberBasicInfo{
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface GoldShopMemberUpdatableInfo{
  name: string
  nationality: string
  dateOfBirth: string
  address: string
  sex: string
  passportNumber: string
  arcNo: string
  arcType: string
  arcOld: string
  arcExpiredDate: string
  arcIssueDate: string
  arcFrontImage: string
  arcBackImage: string
  arcBarcodeNumber: string
  selfieImage: string
  userSignatureImage: string
  phoneNumber: string
  institution: string
}

@Injectable({
  providedIn: 'root',
})
export class GoldshopMemberService {
  constructor(
    private http: HttpClient,
    @Inject(GOLDSHOP_BASE_URL) private goldshopApiUrl: string,
  ) {}

  queryMembers(params: MemberQueryParams):Observable<GoldShopMembers> {
    return this.http.post<GoldShopMembers>(
        this.goldshopApiUrl+ '/gold/api/v0/staff/members',
        params,
    );
  }

  getMemberDetails(id: string): Observable<GoldShopMember> {
    return this.http.get<{body: GoldShopMember}>(
        this.goldshopApiUrl + '/gold/api/v0/staff/member/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateMember(id: string, data: Partial<GoldShopMemberUpdatableInfo>):
  Observable<GoldShopApiResponse> {
    return this.http.put<GoldShopApiResponse>(
        this.goldshopApiUrl + '/gold/api/v0/staff/member/'+ id,
        data,
    );
  }
}
