import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Location} from '@angular/common';
import {UiService} from '@indosuara/common-dialog-ui';


import {StaffService} from '../staff/staff.service';

@Component({
  selector: 'indosuara-staff-form',
  templateUrl: './staff-form.component.html',
  styleUrls: ['./staff-form.component.css'],
})
export class StaffFormComponent {
  constructor(
    private staffSvc: StaffService,
    private uiService: UiService,
    private _location: Location,
  ) {

  }
  hide = true;
  isSubmitting = false;

  formGroup2 = new FormGroup({
    name: new FormControl('', {validators: [Validators.required]}),
    email: new FormControl('',
        {validators: [Validators.email, Validators.required]}),
    password: new FormControl('',
        {validators: [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(50),
        ]}),
  });


  submit(): void {
    this.isSubmitting = true;
    this.formGroup2.disable();
    const staff = {
      email: this.formGroup2.value.email ?? '',
      name: this.formGroup2.value.name ?? '',
      password: this.formGroup2.value.password ?? '',
    };

    this.staffSvc.createStaff(staff).subscribe({
      complete: () => {
        this.uiService.showToast(`${staff.name} account created`);
        this.isSubmitting = false;
        this.formGroup2.disable();
        this._location.back();
      },
    });
  }
}
