
import {BehaviorSubject, EMPTY, Observable, Observer, debounceTime, merge, switchMap} from 'rxjs';

import {Component} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {UiService} from '@indosuara/common-dialog-ui';
import {ImageUploadDialogService} from '@indosuara/ng-image-upload-dialog';

import {FormControl, FormGroup} from '@angular/forms';
import {APIResponse, ContentBannerService, IndoposBanners} from '../content-ui.service';

@Component({
  selector: 'indosuara-banner-list',
  templateUrl: './banner-list.component.html',
  styleUrls: ['./banner-list.component.css'],
})
export class BannerListComponent {
  constructor(
    private imageService: ImageUploadDialogService,
    private bannerService: ContentBannerService,
    private ui: UiService,
  ) {
    this.banners$ = merge(
        this._refresh$.asObservable(),
        this._pageNumber$.asObservable(),
        this._pageSize$.asObservable(),
        this.filter.valueChanges.pipe(debounceTime(300)),
    ).pipe(
        switchMap(()=>{
          return this.bannerService.queryBanners({
            pageNumber: this._pageNumber$.value,
            pageSize: this._pageSize$.value,
            ...this.filter.value,
          });
        }),
    );
  }

  private _refresh$ = new BehaviorSubject(null);
  private _pageNumber$ = new BehaviorSubject(0);
  private _pageSize$ = new BehaviorSubject(10);
  banners$: Observable<IndoposBanners>;
  // isActiveFilterChecked$ = new BehaviorSubject(true);

  filter = new FormGroup({
    isActive: new FormControl<'Y' | 'N' | ''>('Y'),
  });

  get pageSize() {
    return this._pageSize$.value;
  }
  get pageNumber() {
    return this._pageNumber$.value;
  }

  private _actionObserver : Observer<APIResponse>={
    next: (res)=>{
      this._refresh$.next(null);
      this.ui.showToast(res.message ?? '');
    },
    error: (err)=>{
      this._refresh$.next(null);
      this.ui.showDialog('error', err);
    },
    complete: ()=>{
      this._refresh$.next(null);
    },
  };

  newBanner() {
    this.imageService.openImageUploadDialog({
      upload: (file)=> this.bannerService.uploadPublicImage(file),
    }).pipe(
        switchMap((imageUrl)=>{
          if (!imageUrl) return EMPTY;
          return this.bannerService.createBanner({
            imageUrl: imageUrl,
            url: imageUrl,
          });
        }),
    ).subscribe(this._actionObserver);
  }

  changeImage(id: string) {
    this.imageService.openImageUploadDialog(
        {
          upload: (file)=> this.bannerService.uploadPublicImage(file),
        },
    ).pipe(
        switchMap((res)=>{
          if (!res) return EMPTY;
          return this.bannerService.updateBanner(id, {
            imageUrl: res,
          });
        }),
    ).subscribe(this._actionObserver);
  }

  changeLink(id: string) {
    this.ui.showAskTextDialog(
        'Isi Link Baru',
        'Pastikan link valid. contoh https://www.facebook.com',
        'Update Link',
        'Cancel',
    ).pipe(
        switchMap((res)=>{
          if (!res) return EMPTY;
          return this.bannerService.updateBanner(id, {
            url: res,
          });
        }),
    ).subscribe(this._actionObserver);
  }

  bannerActiveChanged(
      id: string, event: MatSlideToggleChange,
  ) {
    this.bannerService.updateBanner(id, {
      isActive: event.checked? 'Y': 'N',
    }).subscribe(this._actionObserver);
  }

  onPaginateChange(event: PageEvent) {
    this._pageNumber$.next(event.pageIndex);
    this._pageSize$.next(event.pageSize);
  }
}
