import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import {map, Observable, tap} from 'rxjs';
import {GoldShopsQueryParams} from './gold-generic-service';
import {GoldOrder, GoldOrderService} from './goldshop-order.service';


export interface HeiMaoExportFormat {
  transactionId: string;
  customerName: string;
  customerAddress: string;
  customerPhone: string;
  note: string;
  productId: '';
  quantity: number;
  volumeWeight: number;
  collectMoney: number;
  sendDate: string;
  sendTime: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoldOrderExportService {
  constructor(
    private service: GoldOrderService,
  ) { }

  renderExportExcel(params: GoldShopsQueryParams):
  Observable<(string | number)[][]> {
    return this.renderPreviewExcel(params).pipe(
        map((res) => this._transformObjectToAoA(res)),
        tap((aoa)=>{
          if (aoa.length > 0) {
            this._writeToXls(aoa, 'Sheet1', 'heimao');
          }
        }),
    );
  }

  renderPreviewExcel(params: GoldShopsQueryParams):
  Observable<HeiMaoExportFormat[]> {
    const result = this.service.query(params);
    return result.pipe(
        map((res) => this.convertToFormatA(res.body ??[])),
    );
  }


  private convertToFormatA(
      invoices: GoldOrder[]): HeiMaoExportFormat[] {
    return invoices.map((invoice) => {
      const hctreport: HeiMaoExportFormat = {
        transactionId: invoice.id,
        customerName: invoice.name ?? '',
        customerAddress: invoice.address ?? '',
        customerPhone: invoice.phoneNumber ?? '',
        note: invoice.itemInfos?.map((item) =>
          item.product.name).join(' + ') ?? '',
        productId: '',
        quantity: invoice.itemInfos?.reduce(
            (agg, curr) => {
              return agg + curr.orderItem.quantity;
            }, 0) ?? 0,
        volumeWeight: 0,
        collectMoney: 0,
        sendDate: '',
        sendTime: 0,
      };
      return hctreport;
    });
  }

  private _transformObjectToAoA(reports: object[]) : (string | number)[][] {
    const keys = Object.keys(reports[0]).map((k) => _.startCase(k));
    const aoa: (string | number)[][] = [keys];
    aoa.push(
        ...reports.map((struct) => {
          return _.values(
              _.mapValues(struct, (value) => {
                if (typeof value === 'string' || typeof value === 'number') {
                  return value;
                }
                return undefined;
              })).filter((value) => value !== undefined);
        }),
    );
    return aoa;
  }

  private _writeToXls(
      aoa: (string | number)[][],
      sheetName: string,
      fileName: string) {
    import('xlsx').then((XLSX) => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      XLSX.writeFile(
          wb,
          this._generateFileName(fileName),
          {
            bookType: 'biff8',
          },
      );
    });
  }
  private _generateFileName(name: string) {
    const localISOTime = moment().format('YYYY-MM-DD_HH-mm-ss');

    const fileName = `emasku-${name}-${localISOTime}.xls`;
    return fileName;
  }
}
