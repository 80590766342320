<mat-card appearance="outlined">
  <mat-card-header>
    <div mat-card-avatar><mat-icon>account_circle</mat-icon></div>
    <mat-card-title>{{ staff.name || 'Not Named' }}</mat-card-title>
    <mat-card-subtitle>
      <span
        class="is-chip chip-manager-color manager-chip"
        *ngIf="staff?.manager"
        >Manager</span
      >
      <span class="is-chip" *ngIf="staff?.staff">Staff</span>
      <span class="is-chip chip-disabled-color" *ngIf="!(staff?.staff ?? true)"
        >Disabled</span
      >
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table>
      <tr>
        <th style="width: 30%">Data</th>
        <th>Value</th>
      </tr>
      <tr>
        <td>E-mail</td>
        <td>
          {{ staff.email }}
          {{ staff.emailVerified ? '' : 'email is not verified' }}
        </td>
      </tr>
      <tr>
        <td>Name</td>
        <td>{{ staff.name }}</td>
      </tr>
      <tr>
        <td>UID</td>
        <td>{{ staff.uid }}</td>
      </tr>
      <tr>
        <td>Date Registered</td>
        <td>{{ staff.creationTime | date: 'full' }}</td>
      </tr>
      <tr>
        <td>Last Sign In</td>
        <td>{{ staff.lastSignInTime | date: 'full' }}</td>
      </tr>
      <tr>
        <td>Last Token Refresh</td>
        <td>{{ staff.lastRefreshTime | date: 'full' }}</td>
      </tr>
      <tr>
        <td>Phone</td>
        <td>{{ staff.phoneNumber }}</td>
      </tr>
      <tr>
        <td>Indopos Agent Code</td>
        <td>{{ staff.idpAgentCode ?? 'none' }}</td>
      </tr>
      <tr>
        <td>Permissions</td>
        <td>
          <mat-chip-listbox>
            <mat-chip-option *ngFor="let item of staff.permissions">
              {{ item }}
            </mat-chip-option>
          </mat-chip-listbox>
        </td>
      </tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <ng-content select="[actions]"></ng-content>
  </mat-card-actions>
</mat-card>
